import "./App.css";
import React, { Children, useEffect } from "react";
import { Snackbar, Alert, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideErrorAlert, showErrorAlert } from "./store/AlertsSlice";
import { useAuth } from "./modules/auth/AuthProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Dashboard } from "./modules/dashboard/Dashboard";
import background from "./images/background.jpg";

export function App({ children }) {
  const globalState = useSelector((state) => state);
  const dispatch = useDispatch();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { code } = useParams();

  const closeErrorAlert = () => {
    dispatch(hideErrorAlert());
  };

  const restrictionMap = [
    { route: "/users/add", roles: ["ROLE_SUPERVISION", ""] },
    { route: "/groups/add", roles: ["ROLE_SUPERVISION", ""] },
    { route: "/roles/add", roles: ["ROLE_SUPERVISION", ""] },
    {
      route: "/tickets/assigned",
      roles: ["ROLE_SUPERVISION", "ROLE_AGENT", "ROLE_BUGS", "ROLE_REPORTS"],
    },
  ];

  useEffect(() => {
    if (auth?.token) {
      let decodedToken = jwtDecode(auth.token);
      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.clear("token");
        localStorage.clear("currentUser");
        navigate("/login");
      }
    }
  }, [auth]);

  useEffect(() => {
    if (restrictionMap.find((r) => location.pathname.startsWith(r.route))) {
      let decodedToken = jwtDecode(auth.token);
      let roles = decodedToken.roles;
      let allowedRoles = restrictionMap.find((r) =>
        location.pathname.startsWith(r.route)
      ).roles;
      if (!allowedRoles.find((ar) => roles.includes(ar))) {
        dispatch(showErrorAlert({ messageError: "Unauthorized" }));
        navigate("/");
      }
    }
  }, [auth]);

  const forgotPasswordRegex = /^\/forgotPassword\/.*$/;

  return (
    <>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Dashboard
          isVisible={
            auth?.token &&
            location.pathname !== "/login" &&
            location.pathname !== "/forgotPassword" &&
            !forgotPasswordRegex.test(location.pathname) &&
            location.pathname !== "*"
          }
        />
        <Box
          style={{
            paddingTop: "64px",
            paddingBottom: "20px",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
          overflow="auto"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
          }}
        >
          {Children.map(children, (child) => child)}
        </Box>
      </Box>

      <Snackbar
        open={globalState?.alerts?.openErrorAlert}
        autoHideDuration={5000}
        onClose={() => closeErrorAlert()}
      >
        <Alert
          onClose={() => closeErrorAlert()}
          severity={globalState?.alerts?.severity ?? "info"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {globalState?.alerts?.messageError}
        </Alert>
      </Snackbar>
    </>
  );
}
