import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  Button,
  TextField,
  Grid,
  Container,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  InputAdornment,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { UsersRepository } from "../../repositories/UsersRepository";
import { Link, useNavigate } from "react-router-dom";

import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PasswordIcon from "@mui/icons-material/Password";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import { Person, PersonOutlined } from "@mui/icons-material";

export const EditUser = ({}) => {
  const { id } = useParams();
  const [formData, setFormData] = useState();

  const navigate = useNavigate();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();

  useEffect(() => {
    if (id) {
      loadUser();
    }
  }, [id]);

  const loadUser = () => {
    UsersRepository.fetchUserById(id)
      .then((res) => {
        setFormData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setChanged(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setErrorMessage();
    setFormDataErrors();
    setLoading(true);

    UsersRepository.editUser(formData)
      .then((res) => {
        setOpenSuccessSnackbar(true);
        loadUser();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
        if (err?.response?.data?.errors) {
          let errorsObj = [];
          err?.response?.data?.errors?.forEach((error) => {
            errorsObj[error.field] = error?.defaultMessage;
          });
          setFormDataErrors(errorsObj);
        }
      })
      .finally(() => {
        setLoading(false);
        setChanged(false);
      });
  };

  return (
    <Container>
      <h1 style={{ fontFamily: "Montserrat" }}>Edit User Info:</h1>
      <Grid container spacing={2} className="wrapper" style={{ width: "100%" }}>
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            size="small"
            id="username"
            name="username"
            label="Username"
            value={formData?.username ?? ""}
            error={formDataErrors?.username ? true : false}
            helperText={formDataErrors?.username}
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            id="firstName"
            name="firstName"
            label="First Name"
            value={formData?.firstName ?? ""}
            error={formDataErrors?.firstName ? true : false}
            helperText={formDataErrors?.firstName}
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            id="lastName"
            name="lastName"
            label="Last Name"
            value={formData?.lastName ?? ""}
            error={formDataErrors?.lastName ? true : false}
            helperText={formDataErrors?.lastName}
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            size="small"
            id="email"
            name="email"
            label="Email"
            value={formData?.email ?? ""}
            error={formDataErrors?.email ? true : false}
            helperText={formDataErrors?.email}
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            value={formData?.phoneNumber ?? ""}
            error={formDataErrors?.phoneNumber ? true : false}
            helperText={formDataErrors?.phoneNumber}
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneEnabledOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ color: "black" }}>
          <label>
            <b>ASSOCIATED GROUPS:</b>
          </label>
          {formData?.groups?.map((group) => (
            <li style={{ listStyle: "none" }} key={group.id}>
              {group.name} ({group?.roles?.map((role) => role.name).join(", ")})
            </li>
          ))}
        </Grid>
        <Grid item>
          <Button
            className="button-30"
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Grid>

        <Grid item>
          <Button
            style={{
              color: "#3d80a8",
            }}
            variant="outlined"
            onClick={() => {
              navigate(`/users/edit/${id}/addGroups`);
            }}
          >
            Add user to group / Remove user from group
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            style={{
              backgroundColor: "#3d80a8",
              display: changed ? "block" : "none",
            }}
            disabled={loading}
            onClick={(e) => {
              handleEdit();
            }}
            variant="contained"
          >
            Submit Edit
          </Button>
        </Grid>
      </Grid>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          User edited successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};
