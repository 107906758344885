import Axios from "axios";
import { showErrorAlert } from "../store/AlertsSlice";
import { ErrorMessageResolver } from "./ErrorMessageResolver";
import { AuthRepository } from "../modules/auth/AuthRepository";
export default {
  setupInterceptors: (store) => {
    // Add a request interceptor

    Axios.interceptors.request.use(
      function (config) {
        if (localStorage.token) {
          if (config.headers.hasAuthorization()) {
            config.headers.Authorization = `Bearer ${localStorage.token}`;
          } else {
            config.headers.Authorization = `Bearer ${localStorage.token}`;
          }
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    // Add a response interceptor
    Axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (err) {
        store.dispatch(
          showErrorAlert({
            messageError: ErrorMessageResolver.resolve(err),
          })
        );

        return Promise.reject(err);
      }
    );
  },
};
