import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Grid,
  Button,
  Container,
  Tooltip,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AuditRepository } from "../../repositories/AuditRepository";
import { ArrowBack, Close, CloseOutlined } from "@mui/icons-material";

export const TicketRevisions = ({ id, open, handleClose }) => {
  const [viewRevision, setViewRevision] = useState();
  const [revisionsData, setRevisionsData] = useState([{}]);
  const [revisionData, setRevisionData] = useState({});
  const [revisionDataToCompare, setRevisionDataToCompare] = useState({});
  const [revisionNumber, setRevisionNumber] = useState();
  const [revisionNumberToCompare, setRevisionNumberToCompare] = useState();

  useEffect(() => {
    fetchTicketRevisions();
  }, [open]);

  useEffect(() => {
    if (revisionNumber != null && revisionNumberToCompare != null) {
      fetchTicketRevisionsToCompare(revisionNumber, revisionNumberToCompare);
    }
  }, [revisionNumber, revisionNumberToCompare]);

  const fetchTicketRevisions = () => {
    AuditRepository.fetchTicketRevisions(id)
      .then((res) => {
        setRevisionsData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTicketRevisionByNumber = (revisionNumber) => {
    AuditRepository.fetchTicketRevisionByNumber(revisionNumber)
      .then((res) => {
        setRevisionData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTicketRevisionsToCompare = (
    revisionNumber,
    revisionNumberToCompare
  ) => {
    AuditRepository.fetchTicketRevisionsToCompare(
      revisionNumber,
      revisionNumberToCompare
    ).then((res) => {
      console.log(res);
      setRevisionData(res?.data?.firstRevision);
      setRevisionDataToCompare(res?.data?.secondRevision);
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="revisions-dialog-title"
        aria-describedby="revisions-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "30px",
          },
          backdropFilter: "blur(2px) sepia(1%)",
          background: "transparent",
        }}
      >
        <DialogContent
          style={{
            paddingBottom: "50px",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "16px",
            }}
          >
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                margin: "8px",
                display: viewRevision ? "flex" : "none",
              }}
              onClick={() => {
                setViewRevision(false);
              }}
            >
              <ArrowBack />
            </IconButton>
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: "8px",
              }}
              onClick={() => {
                setViewRevision(false);
                handleClose();
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
          {!viewRevision ? (
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Montserrat",
                  }}
                >
                  <h2>Ticket Revisions</h2>
                  <Table className="wrapper">
                    <TableHead style={{ background: "#3d80a8" }}>
                      <TableRow>
                        <TableCell style={{ color: "white" }}>
                          Revision number
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "center" }}
                        >
                          Date of revision
                        </TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {revisionsData && revisionsData.length > 0 ? (
                        revisionsData?.map((revision, index) => (
                          <TableRow>
                            <TableCell>{revision[3]}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {moment(revision[4]).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  setRevisionNumber(revision[3]);
                                  if (index < revisionsData.length - 1) {
                                    setRevisionNumberToCompare(
                                      revisionsData[index + 1][3]
                                    );
                                  } else {
                                    setRevisionNumberToCompare(revision[3]);
                                  }
                                  console.log(revisionNumber);
                                  console.log(revisionNumberToCompare);
                                  setViewRevision(true);
                                }}
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell>No revisions available!</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2
                style={{
                  fontFamily: "Montserrat",
                }}
              >
                Compare revisions
              </h2>

              <TableContainer style={{ maxHeight: "600px" }}>
                <Table className="wrapper" style={{ position: "relative" }}>
                  <TableHead
                    style={{
                      background: "#3d80a8",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <TableRow>
                      <TableCell style={{ color: "white" }}>
                        <b>Revision number</b>
                      </TableCell>

                      <TableCell
                        style={{
                          color: "white",
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        <b>{revisionNumberToCompare}</b>
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        <b>{revisionNumber}</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Ticket ID</TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.id}
                      </TableCell>
                      <TableCell>{revisionData?.id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Description</TableCell>

                      <TableCell
                        style={{
                          overflowWrap: "break-word",
                          maxWidth: "200px",
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.description}
                      </TableCell>
                      <TableCell
                        style={{
                          overflowWrap: "break-word",
                          maxWidth: "200px",
                        }}
                      >
                        {revisionData?.description}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Created on</TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {moment(revisionDataToCompare?.createdOn).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(revisionData?.createdOn).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Last modified on</TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.lastModified
                          ? moment(revisionDataToCompare?.lastModified).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.lastModified ===
                            revisionDataToCompare?.lastModified
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.lastModified
                          ? moment(revisionData?.lastModified).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Closed on</TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.closedOn
                          ? moment(revisionDataToCompare?.closedOn).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.closedOn ===
                            revisionDataToCompare?.closedOn
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.closedOn
                          ? moment(revisionData?.closedOn).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Created by</TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.createdBy}
                      </TableCell>
                      <TableCell>{revisionData?.createdBy}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Modified by</TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.modifiedBy}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.modifiedBy ===
                            revisionDataToCompare?.modifiedBy
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.modifiedBy}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assigned agent</TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.assignedAgent?.username}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.assignedAgent?.username ===
                            revisionDataToCompare?.assignedAgent?.username
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.assignedAgent?.username}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <span style={{ fontSize: "13px", fontFamily: "Montserrat" }}>
                  *Changes are highlighted
                </span>
              </TableContainer>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
