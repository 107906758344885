import axios from "axios";

import { unstable_HistoryRouter } from "react-router-dom";
import Config from "../../config";
const { baseURL } = Config.url;
export const AuthRepository = {
  authenticate: (username, password) => {
    return axios({
      url: `${Config.url}/auth/authenticate`,
      method: "POST",
      params: {
        username: username,
        password: password,
      },
    });
  },
  logout: (redirectTo) => {
    localStorage.removeItem("token");
    const history = unstable_HistoryRouter();
    if (redirectTo) {
      history.push(redirectTo);
    } else {
      history.push("/login");
    }
  },
  forbiddenRedirect: (redirectTo) => {
    const history = unstable_HistoryRouter();
    history.push(redirectTo);
  },
  refreshToken: () => {
    return axios({
      url: `${Config.url}/authenticate/refresh`,
      method: "POST",
    });
  },
};
