import {
  TableCell,
  TableRow,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Backdrop,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Alert,
  Snackbar,
  Grid,
  Tooltip,
  Chip,
  Zoom,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";
import { TicketRepository } from "../../repositories/TicketRepository";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddIcon from "@mui/icons-material/Add";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import BlurLinearOutlinedIcon from "@mui/icons-material/BlurLinearOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import UpdateDisabledOutlinedIcon from "@mui/icons-material/UpdateDisabledOutlined";
import AlarmOffOutlinedIcon from "@mui/icons-material/AlarmOffOutlined";
import { TicketRevisions } from "./TicketRevisions";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { AssignmentTurnedIn, Remove } from "@mui/icons-material";

export const Ticket = ({ data, reloadTickets }) => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState(data);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const [revisionsOpen, setRevisionsOpen] = useState(false);

  useEffect(() => {
    if (data?.closedOn != null) {
      setDisabled(true);
      setAssigned(true);
    }
    if (data?.assignedAgent?.username != null) {
      setAssigned(true);
    }
    setTicketData((prevTicketData) => ({
      ...prevTicketData,
      status: { statusName: data?.status?.statusName },
    }));
  }, [data]);

  const isUserAuthorized = () => {
    const decodedToken = jwtDecode(auth.token);
    const roles = decodedToken.roles;
    const allowedRoles = ["ROLE_BUGS", "ROLE_REPORTS"];
    return roles.some((role) => allowedRoles.includes(role));
  };

  const isUserSupervisor = () => {
    const decodedToken = jwtDecode(auth.token);
    const roles = decodedToken.roles;
    return roles.includes("ROLE_SUPERVISION");
  };

  const handleTicketAssignment = () => {
    TicketRepository.updateTicketAssignedAgent(data?.id)
      .then((res) => {
        console.log(res);
        reloadTickets();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleUnassignTicket = () => {
    TicketRepository.unassignTicketAgent(ticketData?.id)
      .then((res) => {
        reloadTickets();
        setAssigned(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleTicketClose = () => {
    TicketRepository.closeTicket(data?.id)
      .then((res) => {
        console.log(res);
        reloadTickets();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTicketDelete = () => {
    TicketRepository.deleteTicket(data?.id)
      .then((res) => {
        console.log(res);
        reloadTickets();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickRevisionsOpen = () => {
    setRevisionsOpen(true);
  };
  const handleClickRevisionsClose = () => {
    setRevisionsOpen(false);
  };

  return (
    <>
      <Card
        className="card"
        style={{
          boxShadow: "0px 5px 14px rgba(0, 0, 0, 0.5)",
          borderRadius: "8px",
        }}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ position: "relative" }}>
              <Button
                color="error"
                style={{
                  position: "absolute",
                  top: 5,
                  right: 0,
                  display:
                    (data?.closedOn != null && isUserSupervisor()) ||
                    (data?.assignedAgent === null &&
                      auth.user === data?.createdBy)
                      ? "flex"
                      : "none",
                }}
                disabled={loading}
                onClick={() => {
                  handleClickOpen();
                }}
              >
                <Tooltip TransitionComponent={Zoom} title="Delete Ticket">
                  <DeleteForeverIcon fontSize="large" />
                </Tooltip>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                component="h2"
                style={{ color: "#376343" }}
              >
                <ErrorOutlineIcon style={{ marginRight: "5px" }} />
                <b
                  style={{
                    fontFamily: "Montserrat",
                  }}
                >
                  {data?.type?.typeName}
                </b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography color="textSecondary">
                <b>
                  <BlurLinearOutlinedIcon
                    fontSize="small"
                    style={{ top: "3px", position: "relative" }}
                  />{" "}
                  Status:
                </b>{" "}
                <Chip
                  size="small"
                  label={ticketData?.status?.statusName}
                  color={
                    ticketData?.status?.statusName === "Resolved"
                      ? "success"
                      : ticketData?.status?.statusName === "Rejected"
                      ? "error"
                      : "primary"
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                <b>
                  <AlarmOutlinedIcon
                    fontSize="small"
                    style={{ top: "3px", position: "relative" }}
                  />{" "}
                  Priority:
                </b>{" "}
                <Chip
                  size="small"
                  label={ticketData?.priority?.priorityName}
                  color={
                    ticketData?.priority?.priorityName === "Low"
                      ? "info"
                      : ticketData?.priority?.priorityName === "Medium"
                      ? "warning"
                      : "error"
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                <b>
                  <PolylineOutlinedIcon
                    fontSize="small"
                    style={{ top: "3px", position: "relative" }}
                  />{" "}
                  Created By:
                </b>
                {"  "}
                {data?.createdBy}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                <b>
                  <PolylineOutlinedIcon
                    fontSize="small"
                    style={{ top: "3px", position: "relative" }}
                  />{" "}
                  Last Modified By:
                </b>
                {"  "}
                {data?.modifiedBy ? data?.modifiedBy : "Not modified by anyone"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                <b>
                  <EmojiPeopleOutlinedIcon
                    fontSize="small"
                    style={{ top: "3px", position: "relative" }}
                  />{" "}
                  Assigned Agent:
                </b>
                {"  "}
                {data?.assignedAgent?.username
                  ? data?.assignedAgent?.username
                  : "Not assigned yet"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                <b>
                  <AccessTimeOutlinedIcon
                    fontSize="small"
                    style={{ top: "3px", position: "relative" }}
                  />{" "}
                  Created On:
                </b>{" "}
                <span style={{ color: "#3162b0", fontWeight: "600" }}>
                  {moment(data?.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                <b>
                  <UpdateOutlinedIcon
                    fontSize="small"
                    style={{ top: "3px", position: "relative" }}
                  />{" "}
                  Last Modified:
                </b>{" "}
                <span
                  style={
                    data?.lastModified
                      ? { color: "#66bb6a", fontWeight: "600" }
                      : {}
                  }
                >
                  {data?.lastModified
                    ? moment(data?.lastModified).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "Not modified yet"}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                <b>
                  <UpdateDisabledOutlinedIcon
                    fontSize="small"
                    style={{ top: "3px", position: "relative" }}
                  />{" "}
                  Closed On:
                </b>{" "}
                <span
                  style={
                    data?.closedOn
                      ? { color: "#0288d1", fontWeight: "600" }
                      : {}
                  }
                >
                  {data?.closedOn
                    ? moment(data?.closedOn).format("MMMM Do YYYY, h:mm:ss a")
                    : "Not closed yet"}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GradingOutlinedIcon />}
                    onClick={() => {
                      navigate(`/tickets/${data?.id}`);
                    }}
                  >
                    View
                  </Button>
                </Grid>
                <Grid
                  item
                  style={{
                    display: isUserAuthorized() && !assigned ? "flex" : "none",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<AddIcon />}
                    disabled={assigned}
                    onClick={() => {
                      handleTicketAssignment();
                    }}
                  >
                    Assign To Me
                  </Button>
                </Grid>
                <Grid
                  item
                  style={{
                    display:
                      isUserAuthorized() &&
                      auth.user === data?.assignedAgent?.username &&
                      assigned &&
                      !data?.closedOn
                        ? "flex"
                        : "none",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Remove />}
                    onClick={() => {
                      handleUnassignTicket();
                    }}
                  >
                    Unassign
                  </Button>
                </Grid>
                <Grid
                  item
                  style={{
                    display:
                      auth.user === data?.assignedAgent?.username ||
                      isUserSupervisor()
                        ? "flex"
                        : "none",
                  }}
                >
                  <Tooltip
                    title="This will close the ticket"
                    TransitionComponent={Zoom}
                  >
                    <Button
                      variant="outlined"
                      color="success"
                      startIcon={<DoneAllIcon />}
                      disabled={disabled}
                      onClick={() => {
                        handleTicketClose();
                      }}
                    >
                      Mark as done
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  style={{ display: isUserSupervisor() ? "flex" : "none" }}
                >
                  <Tooltip
                    title="View history of ticket"
                    TransitionComponent={Zoom}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<EditNoteOutlinedIcon />}
                      onClick={handleClickRevisionsOpen}
                    >
                      Revisions
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete '${ticketData.ticketOwner.username}' ticket:  with id: '${ticketData.id}' from the database?`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleClose();
                handleTicketDelete();
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {revisionsOpen && (
        <TicketRevisions
          id={data.id}
          open={revisionsOpen}
          handleClose={handleClickRevisionsClose}
        />
      )}
    </>
  );
};
