import React, { useEffect, useState } from "react";
import moment from "moment";
import Pako from "pako";
import {
  Grid,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  TablePagination,
  Tooltip,
  Chip,
  Divider,
  InputAdornment,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import PortraitIcon from "@mui/icons-material/Portrait";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useParams, Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";
import "../../App.css";
import { TicketRepository } from "../../repositories/TicketRepository";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import ReplyIcon from "@mui/icons-material/Reply";
import { AddComment } from "../comments/AddComment";
import { CommentRepository } from "../../repositories/CommentRepository";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { DoneAll, Download, FlutterDash, Remove } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CommentIcon from "@mui/icons-material/Comment";
import { AuditRepository } from "../../repositories/AuditRepository";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { TicketRevisions } from "./TicketRevisions";
import { AttachmentRepository } from "../../repositories/AttachmentRepository";

export const TicketView = ({}) => {
  const { id } = useParams();
  const [ticketData, setTicketData] = useState({});
  const [open, setOpen] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [deleteReply, setDeleteReply] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [disabled, setDisabled] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const [openCommentAddSuccessSnackbar, setOpenCommentAddSuccessSnackbar] =
    useState();
  const [
    openCommentDeleteSuccessSnackbar,
    setOpenCommentDeleteSuccessSnackbar,
  ] = useState();
  const auth = useAuth();
  const navigate = useNavigate();
  const [comments, setComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [replyText, setReplyText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [commentCount, setCommentCount] = useState(10);
  const [commentToDelete, setCommentToDelete] = useState();
  const [revisionsOpen, setRevisionsOpen] = useState(false);

  const isUserAuthorized = () => {
    const decodedToken = jwtDecode(auth.token);
    const roles = decodedToken.roles;
    const allowedRoles = ["ROLE_BUGS", "ROLE_REPORTS"];
    return roles.some((role) => allowedRoles.includes(role));
  };

  const isUserSupervisor = () => {
    const decodedToken = jwtDecode(auth.token);
    const roles = decodedToken.roles;
    return roles.includes("ROLE_SUPERVISION");
  };

  useEffect(() => {
    document.title = "Ticket Info | Ticketing System";
  }, []);

  useEffect(() => {
    fetchTicketById(id);
    loadAllComments();
  }, [id, page, rowsPerPage]);

  useEffect(() => {
    if (ticketData?.closedOn != null) {
      setDisabled(true);
      setAssigned(true);
    }
    if (ticketData?.assignedAgent?.username != null) {
      setAssigned(true);
    }
  }, [ticketData]);

  const loadAllComments = () => {
    let criteriaList = [{}];
    CommentRepository.fetchAllComments(id, page, rowsPerPage, criteriaList)
      .then((res) => {
        console.log(res);
        setComments(res.data?.content);
        setCommentCount(res.data?.totalElements);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleTicketAssignment = () => {
    TicketRepository.updateTicketAssignedAgent(ticketData?.id)
      .then((res) => {
        console.log(res);
        fetchTicketById(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleUnassignTicket = () => {
    TicketRepository.unassignTicketAgent(ticketData?.id)
      .then((res) => {
        fetchTicketById(id);
        setAssigned(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTicketById = (id) => {
    TicketRepository.fetchTicketById(id)
      .then((res) => {
        setTicketData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleDeleteReplyOpen = () => {
    setDeleteReply(true);
  };

  const handleDeleteReplyClose = () => {
    setDeleteReply(false);
    setLoading(false);
  };

  const handleRemove = () => {
    TicketRepository.deleteTicket(ticketData?.id)
      .then((res) => {
        console.log(res);
        navigate("/tickets");
        setOpenSuccessSnackbar(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTicketClose = () => {
    TicketRepository.closeTicket(ticketData?.id)
      .then((res) => {
        console.log(res);
        fetchTicketById(ticketData?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addComment = (data) => {
    setLoading(true);
    setErrorMessage();
    CommentRepository.addCommentToTicket(data)
      .then((res) => {
        console.log(res);
        setOpenCommentAddSuccessSnackbar(true);
        loadAllComments();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        setErrorMessage(err?.response?.data?.message);
        console.log(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReply = (commentId) => {
    setSelectedComment(commentId);
  };

  const handleCloseModal = () => {
    setSelectedComment(null);
    setReplyText("");
  };

  const handleReplyTextChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleSubmitReply = async () => {
    await CommentRepository.addReplyToComment(selectedComment, replyText)
      .then((res) => {
        console.log(res);
        loadAllComments();
      })
      .catch((err) => {
        console.log(err);
      });
    handleCloseModal();
  };

  const handleRemoveComment = () => {
    CommentRepository.deleteComment(commentToDelete)
      .then((res) => {
        console.log(res);
        loadAllComments();
        setOpenCommentDeleteSuccessSnackbar(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickRevisionsOpen = () => {
    setRevisionsOpen(true);
  };
  const handleClickRevisionsClose = () => {
    setRevisionsOpen(false);
  };

  const handleFileDownload = async (attachmentIds) => {
    try {
      const response = await AttachmentRepository.downloadMultipleAttachments(
        attachmentIds
      );

      console.log(response.data);
      var link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.download = "ticket_files." + response.data.type.split("/").pop();
      link.click();
    } catch (err) {
      console.error("Failed to download file:", err);
    }
  };

  const handleAttachmentDownload = async (attachmentId, attachmentName) => {
    try {
      const response = await AttachmentRepository.downloadAttachment(
        attachmentId
      );

      console.log(response.data);
      var link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.download = attachmentName;
      link.click();
    } catch (err) {
      console.error("Failed to download file:", err);
    }
  };

  const getAttachmentIds = () => {
    if (ticketData?.attachments) {
      return ticketData?.attachments?.map((attachment) => attachment.id);
    }
    return [];
  };

  return (
    <>
      <Container
        className="wrapper"
        style={{
          color: "black",
          width: "60%",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Grid
          container
          spacing={2}
          p={3}
          sx={{ fontWeight: "light", fontSize: "1.5em" }}
        >
          <Grid
            item
            xs={12}
            style={{
              color: "#3162b0",

              marginTop: "-5px",
              marginBottom: "50px",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <h2 style={{ color: "#3d80a8", fontFamily: "Montserrat" }}>
                <ConfirmationNumberOutlinedIcon
                  style={{
                    fontSize: "40px",
                    paddingTop: "5px",
                  }}
                />{" "}
                Ticket Info
              </h2>
              <Grid
                item
                style={{
                  fontFamily: "Montserrat",
                }}
              >
                <div style={{ display: "flex", fontSize: "16px" }}>
                  <Tooltip title="Date Created">
                    <ScheduleIcon style={{ marginTop: "5px" }} />{" "}
                    <b>
                      <span style={{ color: "#3162b0" }}>
                        {" "}
                        {moment(ticketData?.createdOn).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}{" "}
                      </span>
                    </b>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: ticketData.lastModified !== null ? "flex" : "none",
                    fontSize: "16px",
                  }}
                >
                  <Tooltip title="Last Modified">
                    <UpdateIcon
                      style={{
                        fontSize: "27px",
                        color: "green",
                      }}
                    />{" "}
                    <b>
                      <span
                        style={{
                          color: "#66bb6a",
                          position: "absolute",
                          marginTop: "5px",
                        }}
                      >
                        {ticketData?.lastModified
                          ? moment(ticketData?.lastModified).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}
                      </span>
                    </b>
                  </Tooltip>
                </div>
                <Divider />
                <div
                  style={{
                    display: ticketData.closedOn !== null ? "flex" : "none",
                    fontSize: "16px",
                  }}
                >
                  <Tooltip title="Date Closed">
                    <DoneAllIcon style={{ color: "#0288d1" }} />
                    <b>
                      <span
                        style={{
                          color: "#0288d1",
                          position: "absolute",
                          marginTop: "2px",
                        }}
                      >
                        {ticketData?.closedOn
                          ? moment(ticketData?.closedOn).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}{" "}
                      </span>
                    </b>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            style={{
              margin: "10px",
              width: "200px",
              display: isUserSupervisor() ? "flex" : "none",
            }}
            onClick={handleClickRevisionsOpen}
            startIcon={<EditNoteOutlinedIcon />}
          >
            Revisions
          </Button>
          <TableContainer
            component={Paper}
            style={{
              marginBottom: "50px",
              boxShadow: "0px 5px 14px rgba(0, 0, 0, 0.5)",
              borderRadius: "8px",
            }}
          >
            <Table sx={{ fontSize: "small" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Description</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"> </TableCell>
                  <TableCell
                    align="left"
                    style={{ overflowWrap: "break-word", maxWidth: "200px" }}
                  >
                    {ticketData?.description}
                  </TableCell>
                  <TableCell align="left"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Type</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"> </TableCell>
                  <TableCell align="left">
                    <Chip label={ticketData?.type?.typeName} color={"info"} />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow xs={3}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Status</b>
                  </TableCell>

                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">
                    <Chip
                      label={ticketData?.status?.statusName}
                      color={
                        ticketData?.status?.statusName === "Resolved"
                          ? "success"
                          : ticketData?.status?.statusName === "Rejected"
                          ? "error"
                          : "primary"
                      }
                    />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Priority</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">
                    <Chip
                      label={ticketData?.priority?.priorityName}
                      color={
                        ticketData?.priority?.priorityName === "Low"
                          ? "info"
                          : ticketData?.priority?.priorityName === "Medium"
                          ? "warning"
                          : "error"
                      }
                    />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Created By</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">{ticketData?.createdBy}</TableCell>

                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow
                  style={{
                    display:
                      ticketData?.modifiedBy != null ? "table-row" : "none",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Modified By</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>

                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">{ticketData?.modifiedBy}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow
                  style={{
                    display:
                      ticketData?.assignedAgent != null ? "table-row" : "none",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Assigned Agent</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>

                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">
                    {ticketData?.assignedAgent?.username}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow
                  style={{
                    display:
                      ticketData?.attachments?.length !== 0
                        ? "table-row"
                        : "none",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Uploaded Files</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>

                  <TableCell align="left">
                    <Grid item>
                      <Button
                        color="success"
                        size="small"
                        startIcon={<Download />}
                        variant="contained"
                        onClick={() => handleFileDownload(getAttachmentIds())}
                      >
                        Download All ({ticketData?.attachments?.length})
                      </Button>
                    </Grid>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container>
            <Grid item xs={12}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#3d80a8",
                }}
              >
                All Files
              </span>
              <ul
                style={{
                  listStyle: "none",
                  fontSize: "15px",
                  display: "block",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {ticketData?.attachments?.map((attachment) => (
                  <li
                    style={{
                      display: "inline-block",
                      width: "200px",
                      maxWidth: "200px",
                      overflowWrap: "break-word",
                      maxHeight: "100px",
                      minHeight: "100px",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                    className="wrapper-smaller"
                    key={attachment.id}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        {attachment.name.slice(0, 15) + "..."}
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() =>
                            handleAttachmentDownload(
                              attachment.id,
                              attachment.name
                            )
                          }
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <Download />
                        </Button>
                      </Grid>
                    </Grid>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  style={{ width: "150px" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              </Grid>

              <Grid
                item
                style={{
                  display:
                    (auth.user === ticketData?.assignedAgent?.username ||
                      isUserSupervisor()) &&
                    !ticketData?.closedOn
                      ? "flex"
                      : "none",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    width: "150px",
                  }}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    navigate(`/tickets/${id}/edit`);
                  }}
                >
                  Edit
                </Button>
              </Grid>
              <Grid
                item
                style={{
                  display: isUserAuthorized() && !assigned ? "flex" : "none",
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<AddIcon />}
                  disabled={assigned}
                  onClick={() => {
                    handleTicketAssignment();
                  }}
                >
                  Assign To Me
                </Button>
              </Grid>
              <Grid
                item
                style={{
                  display:
                    isUserAuthorized() &&
                    auth.user === ticketData?.assignedAgent?.username &&
                    assigned &&
                    !ticketData?.closedOn
                      ? "flex"
                      : "none",
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<Remove />}
                  onClick={() => {
                    handleUnassignTicket();
                  }}
                >
                  Unassign
                </Button>
              </Grid>
              <Grid
                item
                style={{
                  display:
                    auth.user === ticketData?.assignedAgent?.username ||
                    isUserSupervisor()
                      ? "flex"
                      : "none",
                }}
              >
                <Tooltip title="This will close the ticket">
                  <Button
                    variant="outlined"
                    color="success"
                    startIcon={<DoneAllIcon />}
                    disabled={disabled}
                    onClick={() => {
                      handleTicketClose();
                    }}
                  >
                    Mark as done
                  </Button>
                </Tooltip>
              </Grid>

              <Grid
                item
                style={{
                  display:
                    ticketData?.closedOn != null && isUserSupervisor()
                      ? "flex"
                      : "none",
                }}
              >
                <Button
                  style={{
                    width: "150px",
                  }}
                  variant="outlined"
                  color="error"
                  disabled={loading}
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => {
                    setLoading(true);
                    handleClickOpen();
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container className="wrapper" style={{ color: "black" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ justifyContent: "flex-start" }}>
            <Grid item xs={12}>
              {errorMessage && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}
              <AddComment handleSubmit={addComment} ticketData={ticketData} />
            </Grid>
            <Grid item xs={4}>
              <TablePagination
                component="div"
                count={commentCount}
                rowsPerPageOptions={[5, 10, 15, 20]}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>

            <List>
              {comments
                .sort(
                  (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
                )
                .filter(
                  (comment) =>
                    !comment.internalNote || comment.createdBy === auth.user
                )
                .map((comment) => (
                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "10px",
                      paddingRight: "10px",
                    }}
                    className="wrapper-smaller"
                  >
                    <label style={{ paddingTop: "5px" }}>
                      <PersonPinCircleIcon /> <b>{comment.createdBy}</b>
                      <div style={{ position: "absolute", top: 25, right: 0 }}>
                        <Button
                          color="error"
                          style={{
                            marginLeft: "15px",
                            marginBottom: "10px",
                            marginTop: "-10px",
                          }}
                          onClick={() => {
                            setLoading(true);
                            setCommentToDelete(comment.id);
                            handleDeleteReplyOpen();
                          }}
                        >
                          <Tooltip title="Delete Comment">
                            <DeleteForeverIcon />
                          </Tooltip>
                        </Button>
                      </div>
                    </label>

                    <ListItem style={{ marginBottom: "5px" }} key={comment.id}>
                      <ListItemText
                        primary={comment.text}
                        secondary={
                          comment.internalNote
                            ? "Internal Note"
                            : "Public Comment"
                        }
                      />
                    </ListItem>
                    <Button
                      size="small"
                      style={{
                        marginLeft: "5px",
                        marginBottom: "10px",
                        marginTop: "-10px",
                      }}
                      onClick={() => {
                        handleReply(comment.id);
                      }}
                    >
                      <ReplyIcon />
                      Reply
                    </Button>

                    <label style={{ marginLeft: "75%", color: "#909599" }}>
                      {moment(comment.dateCreated).fromNow()}
                    </label>

                    {comment.replies.length > 0 && (
                      <List style={{ marginLeft: "20px" }}>
                        {comment.replies
                          .sort(
                            (a, b) =>
                              new Date(a.dateCreated) - new Date(b.dateCreated)
                          )
                          .map((reply) => (
                            <Grid
                              item
                              className="wrapper-smaller"
                              style={{ margin: "10px" }}
                            >
                              <label>
                                <PersonPinCircleIcon />
                                <b>{reply.createdBy}</b>{" "}
                              </label>
                              <ListItem
                                key={reply.id}
                                style={{
                                  marginLeft: "-15px",
                                  marginTop: "-10px",
                                }}
                              >
                                <ListItemText
                                  style={{ marginTop: "20px" }}
                                  primary={reply.text}
                                  secondary={"Reply"}
                                />
                              </ListItem>
                              <label
                                style={{
                                  marginLeft: "82%",
                                  color: "#909599",
                                }}
                              >
                                {moment(reply?.dateCreated).fromNow()}
                              </label>
                            </Grid>
                          ))}
                      </List>
                    )}
                    {selectedComment === comment.id && (
                      <div className="modal">
                        <div className="modal-content">
                          <TextField
                            value={replyText}
                            onChange={(e) => {
                              handleReplyTextChange(e);
                            }}
                            placeholder="Type your reply here..."
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CommentIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <div>
                            <Button
                              onClick={handleCloseModal}
                              size="small"
                              color="error"
                            >
                              Cancel
                            </Button>
                            <Button onClick={handleSubmitReply} size="small">
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Grid>
                ))}
            </List>
          </Grid>
        </Grid>
      </Container>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete '${ticketData.ticketOwner.username}' ticket:  with id: '${ticketData.id}' from the database?`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleClose();
                handleRemove();
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {deleteReply && (
        <Dialog
          open={deleteReply}
          onClose={handleDeleteReplyClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete this comment?`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDeleteReplyClose}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleDeleteReplyClose();
                handleRemoveComment();
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Ticket deleted successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openCommentAddSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenCommentAddSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenCommentAddSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Comment added successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openCommentDeleteSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenCommentDeleteSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenCommentDeleteSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Comment deleted successfully!
        </Alert>
      </Snackbar>
      {revisionsOpen && (
        <TicketRevisions
          id={ticketData.id}
          open={revisionsOpen}
          handleClose={handleClickRevisionsClose}
        />
      )}
    </>
  );
};
