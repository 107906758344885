export const ErrorMessageResolver = {
  resolve: (err) => {
    if (err?.message && err?.message?.includes("Network Error")) {
      return err.message;
    }
    if (err.response?.data && typeof err.response?.data === "object") {
      if (err.response?.data?.error.includes("Bad Request")) {
        return (
          err.response?.data?.error +
          ": The request didn't go through, please check your input!"
        );
      }
      if (err.response?.data?.error.includes("Forbidden")) {
        return (
          err.response?.data?.error + ": You do not have access to this data!"
        );
      }
      if (err.response?.data?.error.includes("Unauthorized")) {
        return (
          err.response?.data?.error +
          ": You are not authorized to view this data!"
        );
      }
      if (err.response?.data?.error.includes("Not found")) {
        return (
          err.response?.data?.error +
          ": The data you are looking for could not be found!"
        );
      }
      if (err.response?.data?.error.includes("Request timeout")) {
        return (
          err.response?.data?.error + ": The server took too long to respond!"
        );
      }
      if (err.response?.data?.error.includes("Internal Server Error")) {
        return (
          err.response?.data?.error + ": Could not execute desired action!"
        );
      }
    } else if (err?.message.includes("Request failed with status code 413")) {
      return "Request too large: Upload a smaller file!";
    } else if (err?.response?.data) {
      return err.response?.data?.error;
    }
  },
};
