import React, { useEffect, useState } from "react";
import "../../App.css";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import GroupIcon from "@mui/icons-material/Group";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { useNavigate } from "react-router-dom";
import AuthProvider, { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
  Snackbar,
  Alert,
  TablePagination,
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Tooltip,
  Backdrop,
  CircularProgress,
  TableContainer,
} from "@mui/material";
import { UsersRepository } from "../../repositories/UsersRepository";
import { User } from "./User";
import SearchIcon from "@mui/icons-material/Search";
export const Users = () => {
  const [users, setUsers] = useState();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userCount, setUserCount] = useState(10);
  const [open, setOpen] = useState(false);
  const [filterByUsername, setFilterByUsername] = useState();
  const [filterByFirstName, setFilterByFirstName] = useState();
  const [filterByLastName, setFilterByLastName] = useState();
  const [filterByEmail, setFilterByEmail] = useState();
  const [clearFilterFlag, setClearFilterFlag] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    document.title = "Users | Ticketing System";
  }, []);

  useEffect(() => {
    loadAllUsers();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (clearFilterFlag === true) {
      loadAllUsers();
    }
    setClearFilterFlag(false);
  }, [clearFilterFlag]);

  const removeUser = (id) => {
    UsersRepository.deleteUser(id)
      .then((res) => {
        setOpenSuccessSnackbar(true);
        loadAllUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editUser = (user) => {
    UsersRepository.editUser(user)
      .then((res) => {
        loadAllUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadAllUsers = () => {
    let criteriaList = [
      {
        key: "username",
        operation: "CONTAINS",
        value: filterByUsername,
      },
      {
        key: "firstName",
        operation: "CONTAINS",
        value: filterByFirstName,
      },
      {
        key: "lastName",
        operation: "CONTAINS",
        value: filterByLastName,
      },
      {
        key: "email",
        operation: "CONTAINS",
        value: filterByEmail,
      },
    ];
    setLoading(true);
    UsersRepository.fetchAllUsers(page, rowsPerPage, criteriaList)
      .then((res) => {
        setUsers(res.data);
        setUserCount(res.data?.totalElements);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClearFilter = () => {
    setFilterByUsername();
    setFilterByFirstName();
    setFilterByLastName();
    setFilterByEmail();
    setClearFilterFlag(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container className="wrapper-container">
        <div
          style={{
            fontFamily: "Montserrat",
            position: "relative",
          }}
        >
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#3d80a8",
              marginLeft: "-50px",
            }}
          >
            Active Users
          </h1>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <label style={{ marginTop: "40px" }}>Filtering: </label>
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="medium"
                type="text"
                value={filterByUsername || ""}
                autoComplete={false}
                name="filter_by_username_field"
                label="By username"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    loadAllUsers();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setFilterByUsername();
                  } else {
                    setFilterByUsername(event.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="medium"
                type="text"
                value={filterByFirstName || ""}
                autoComplete={false}
                name="filter_by_firstName_field"
                label="By first name"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    loadAllUsers();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setFilterByFirstName();
                  } else {
                    setFilterByFirstName(event.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="medium"
                type="text"
                value={filterByLastName || ""}
                autoComplete={false}
                name="filter_by_lastName_field"
                label="By last name"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    loadAllUsers();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setFilterByLastName();
                  } else {
                    setFilterByLastName(event.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="medium"
                type="text"
                value={filterByEmail || ""}
                autoComplete={false}
                name="filter_by_email_field"
                label="By email"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    loadAllUsers();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setFilterByEmail();
                  } else {
                    setFilterByEmail(event.target.value);
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tooltip title="Apply filtering">
                <Button
                  style={{
                    width: "100px",
                  }}
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    loadAllUsers();
                  }}
                >
                  <SearchIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tooltip title="Clear filtering">
                <Button
                  style={{
                    width: "100px",
                  }}
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    handleClearFilter();
                  }}
                >
                  Clear
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Table className="wrapper">
              <TableHead style={{ background: "#3d80a8" }}>
                <TableRow>
                  <TableCell style={{ textAlign: "center", color: "white" }}>
                    Username
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "white" }}>
                    First name
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "white" }}>
                    Last name
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "white" }}>
                    E-Mail
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "white" }}>
                    Phone number
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "white" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.content?.map((item) => (
                  <User
                    key={item?.id}
                    data={item}
                    handleRemove={removeUser}
                    handleEdit={editUser}
                  />
                ))}
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  count={userCount}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{
                    width: "400%",
                  }}
                />
              </TableBody>
            </Table>
          </div>
        </div>
      </Container>

      {loading && (
        <Backdrop
          sx={{
            color: "#fffff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" size={60} thickness={4} />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="outlined"
          sx={{ width: "100%" }}
        >
          User deleted successfully!
        </Alert>
      </Snackbar>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Log out confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to log out?`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleClose}>
              No
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                handleClose();
                auth.logOut();
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
