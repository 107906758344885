import { createSlice } from "@reduxjs/toolkit";
export const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    message: null,
    severity: null,
    open: false,
    openErrorAlert: false,
    messageError: null,
  },
  reducers: {
    showAlert: (state, action) => {
      if (state.open === true) {
        state.open = false;
        state.message = action.payload.message;
        state.severity = action.payload.severity;
        state.open = true;
      } else {
        state.message = action.payload.message;
        state.severity = action.payload.severity;
        state.open = true;
      }
    },
    hideAlert: (state, action) => {
      state.open = false;
    },
    showErrorAlert: (state, action) => {
      if (state.openErrorAlert === true) {
        state.openErrorAlert = false;
        state.messageError = action.payload.messageError;
        state.severity = "error";
        state.openErrorAlert = true;
      } else {
        state.messageError = action.payload.messageError;
        state.severity = "error";
        state.openErrorAlert = true;
      }
    },
    hideErrorAlert: (state, action) => {
      state.openErrorAlert = false;
    },
  },
});

export const { showAlert, hideAlert, showErrorAlert, hideErrorAlert } =
  alertsSlice.actions;
export default alertsSlice.reducer;
