import axios from "axios";
import Config from "../config";
const { baseURL } = Config.url;

export const GroupRepository = {
  createGroup: (groupName) => {
    return axios({
      url: `${Config.url}/groups`,
      method: "POST",
      data: groupName,
    });
  },
  fetchGroupById: (id) => {
    return axios({
      url: `${Config.url}/groups/${id}`,
      method: "GET",
    });
  },
  fetchAllGroups: (page, rowsPerPage) => {
    return axios({
      url: `${Config.url}/groups`,
      method: "GET",
      data: [{}],
      params: {
        page: page,
        size: 20,
      },
    });
  },
  AddGroupsToUser: (userId, groups) => {
    return axios({
      url: `${Config.url}/groups/addUser`,
      method: "POST",
      data: {
        userId: userId,
        groups: groups,
      },
    });
  },
  deleteGroupById: (groupId) => {
    return axios({
      url: `${Config.url}}/groups/${groupId}`,
      method: "DELETE",
    });
  },
};
