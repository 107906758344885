import { Grid } from "@mui/material";
import React from "react";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
export const Home = ({}) => {
  return (
    <>
      <Grid
        container
        style={{
          fontFamily: "Montserrat",
          alignContent: "center",
          marginTop: "100px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            fontSize: "50px",
            fontWeight: 500,
            color: "#3d80a8",
            display: "flex",
            justifyContent: "center",
          }}
        >
          WELCOME TO OUR TICKETING SYSTEM
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
          }}
        >
          USE THE DASHBOARD TO NAVIGATE
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <BugReportOutlinedIcon
            style={{
              fontSize: "50px",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
