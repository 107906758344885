import {
  Grid,
  Alert,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Container,
  createTheme,
  ThemeProvider,
  withTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState, useContext, createContext } from "react";
import { useAuth } from "./AuthProvider";
import "../../App.css";
import "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PasswordIcon from "@mui/icons-material/Password";
import EmailIcon from "@mui/icons-material/Email";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UsersRepository } from "../../repositories/UsersRepository";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5894b1",
    },
    secondary: {
      main: "#ffab91",
    },
  },
});

export const ForgotPassword = ({}) => {
  const [newPassword, setNewPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const { "*": code } = useParams();
  const [loading, setLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openNewPasswordSuccessSnackbar, setOpenNewPasswordSuccessSnackbar] =
    useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });
  const [repeatPasswordValues, setRepeatPasswordValues] = useState({
    repeatPassword: "",
    showRepeatPassword: false,
  });

  useEffect(() => {
    document.title = "Login | Ticketing System";
  }, []);

  useEffect(() => {
    setErrorMessage(auth.errorMessage);
  }, [auth.errorMessage]);

  useEffect(() => {
    let timer;
    if (openNewPasswordSuccessSnackbar) {
      timer = setTimeout(() => {
        setOpenNewPasswordSuccessSnackbar(false);
        navigate("/login");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [openNewPasswordSuccessSnackbar]);

  const handleSave = (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (newPassword !== "" && repeatPassword !== "") {
      if (repeatPassword !== newPassword) {
        setErrorMessage("Passwords don't match!");
      } else {
        setLoading(true);
        UsersRepository.resetPassword(newPassword, code)
          .then((res) => {
            console.log(res);
            setNewPassword();
            setRepeatPassword();
            setOpenNewPasswordSuccessSnackbar(true);
          })
          .catch((err) => {
            console.log(err);
            setErrorMessage(err?.response?.data?.message);
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }
    } else {
      setErrorMessage("Please provide all necessary fields!");
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };
  const handleClickShowRepeatPassword = () => {
    setRepeatPasswordValues({
      ...repeatPasswordValues,
      showRepeatPassword: !repeatPasswordValues.showRepeatPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "800px",
            width: "500px",
            borderRadius: "50px",
            flexWrap: "wrap",
            boxShadow: "0 10px 80px 5px #5894b1",
            backgroundSize: "1200px",
            marginTop: "-25px",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              marginTop: "-10%",
              width: "40vh",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                fontSize: "50px",
                fontWeight: "bold",
                lineHeight: "1.2",
                fontFamily: "Roboto Slab",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "#5894b1",
                fontFamily: "Dosis",
              }}
            >
              <Grid item xs={12}>
                <dotlottie-player
                  src="https://lottie.host/4ef6d14e-973f-45f5-88b7-3f8a759b319d/0J04cXBnSn.json"
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></dotlottie-player>
              </Grid>
              <Grid item xs={12}>
                <span>
                  Ticket Portal <br />
                  <span style={{ fontSize: "25px", fontWeight: "lighter" }}>
                    Access Your Service Panel
                  </span>
                </span>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              {errorMessage && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  type={passwordValues.showPassword ? "text" : "password"}
                  value={newPassword || ""}
                  label="New password"
                  error={inputErrors?.newPassword ? true : false}
                  helperText={inputErrors?.newPassword}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSave(e);
                  }}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {passwordValues.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  type={
                    repeatPasswordValues.showRepeatPassword
                      ? "text"
                      : "password"
                  }
                  value={repeatPassword || ""}
                  label="Repeat password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSave(e);
                  }}
                  onChange={(e) => {
                    setRepeatPassword(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowRepeatPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {passwordValues.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "-webkit-box",
                  display: "-webkit-flex",
                  display: "-moz-box",
                  display: "-ms-flexbox",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    width: "200px",
                    display: "block",
                    position: "relative",
                    borderRadius: "25px",
                    overflow: "hidden",
                    margin: "0 auto",
                    boxShadow: "0 5px 15px 0px #5894b1",
                    color: "#3e687c",
                    borderColor: "#5894b1",
                  }}
                  id="loginButton"
                  variant="outlined"
                  disabled={loading}
                  onClick={(e) => {
                    handleSave(e);
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Back to login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Snackbar
          open={openNewPasswordSuccessSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenNewPasswordSuccessSnackbar()}
        >
          <Alert
            onClose={() => setOpenNewPasswordSuccessSnackbar()}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            New password updated successfully!
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  );
};
