import React, { useContext, createContext, useState, useEffect } from "react";
import { AuthRepository } from "./AuthRepository";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Alert,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { UsersRepository } from "../../repositories/UsersRepository";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("currentUser") || "");
  const [token, setToken] = useState(localStorage.getItem("token") || "");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState();
  const [inputErrors, setInputErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setErrorMessage(error?.response?.data?.message);
  }, [error]);

  const loginAction = async (data) => {
    setErrorMessage("");
    setLoading(true);
    await AuthRepository.authenticate(data.username, data.password)
      .then((res) => {
        setUser(data.username);
        setToken(res.data.body.jwt);
        localStorage.setItem("token", res.data.body.jwt);
        localStorage.setItem("currentUser", data.username);

        setOpenSuccessSnackbar(true);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    navigate("/login");
  };

  return (
    <>
      <AuthContext.Provider
        value={{ token, user, loginAction, logOut, errorMessage }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
