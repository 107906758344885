import axios from "axios";
import Config from "../config";
const { baseURL } = Config.url;

export const UsersRepository = {
  fetchAllUsers: (page, rowsPerPage, criteriaList) => {
    return axios({
      url: `${Config.url}/users/search`,
      method: "POST",
      data: criteriaList,
      params: {
        page: page,
        size: rowsPerPage,
      },
    });
  },
  fetchUserById: (id) => {
    return axios({
      url: `${Config.url}/users/${id}`,
      method: "GET",
    });
  },
  fetchCurrentUser: (username) => {
    return axios({
      url: `${Config.url}/users/currentUser`,
      method: "GET",
    });
  },
  createUser: (user) => {
    return axios({
      url: `${Config.url}/users`,
      method: "POST",
      data: user,
    });
  },

  editUser: (user) => {
    return axios({
      url: `${Config.url}/users`,
      method: "PUT",
      data: user,
    });
  },
  updatePassword: (passwordData) => {
    return axios({
      url: `${Config.url}/users/password`,
      method: "PUT",
      data: passwordData,
    });
  },
  resetPassword: (newPassword, code) => {
    return axios({
      url: `${Config.url}/users/forgotPassword`,
      method: "PUT",
      data: {
        newPassword: newPassword,
        code: code,
      },
    });
  },
  sendUsername: (username) => {
    return axios({
      url: `${Config.url}/auth/authenticate/forgotPassword`,
      method: "PUT",
      params: {
        username: username,
      },
    });
  },
  deleteUser: (userId) => {
    return axios({
      url: `${Config.url}/users/${userId}`,
      method: "DELETE",
    });
  },
};
