import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Pagination,
  Container,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import "../../App.css";
import GroupIcon from "@mui/icons-material/Group";
import { GroupRepository } from "../../repositories/GroupRepository";
import { useNavigate } from "react-router-dom";
import {
  Clear,
  ClearAll,
  DeleteForever,
  GroupAddOutlined,
} from "@mui/icons-material";

export const AddGroup = ({}) => {
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState();
  const [groupName, setGroupName] = useState({
    name: "",
  });
  const [groupData, setGroupData] = useState({
    id: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [groupNameError, setGroupNameError] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [groupCount, setGroupCount] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Groups | Ticketing System";
  }, []);

  useEffect(() => {
    loadAllGroups();
  }, [page, rowsPerPage]);

  const loadAllGroups = () => {
    GroupRepository.fetchAllGroups(page, rowsPerPage)
      .then((res) => {
        setGroups(res.data);
        setGroupCount(res.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (group) => {
    setErrorMessage();
    setGroupNameError();
    setLoading(true);
    GroupRepository.createGroup(group)
      .then((res) => {
        setOpenSuccessSnackbar(true);
        setGroupName({ name: "" });
        loadAllGroups();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
        if (err?.response?.data?.errors) {
          let errorsObj = [];
          err?.response?.data?.errors?.forEach((error) => {
            errorsObj[error.field] = error?.defaultMessage;
          });
          setGroupNameError(errorsObj);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleRemove = (groupId) => {
    setErrorMessage();
    setLoading(true);
    GroupRepository.deleteGroupById(groupId)
      .then((res) => {
        console.log(res);
        setOpenDeleteSnackbar(true);
        loadAllGroups();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Container
        p={{ xs: 1 }}
        className="wrapper"
        style={{
          color: "black",
          marginTop: "20px",
          width: "40%",
          textAlign: "center",
        }}
      >
        <h2 style={{ fontFamily: "Montserrat", color: "#3d80a8" }}>
          Groups (Roles) available
        </h2>
        <label style={{ fontFamily: "Montserrat" }}>
          (Click on the group to add roles)
        </label>

        <Grid container>
          {groups?.content?.map((group) => (
            <Grid
              item
              xs={12}
              className="wrapper-smaller"
              style={{
                margin: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={11}
                style={{
                  cursor: "pointer",
                }}
                key={group.id}
                onClick={() => {
                  navigate(`/groups/add/${group.id}/addRoles`);
                }}
              >
                {group.name} (
                {group?.roles?.map((role) => role.name).join(", ")})
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Delete Group">
                  <Button
                    variant="text"
                    color="error"
                    disabled={loading}
                    onClick={() => {
                      setLoading(true);
                      setGroupData({
                        id: group.id,
                        name: group.name,
                      });
                      console.log(groupData);
                      handleClickOpen();
                    }}
                  >
                    <Clear style={{ fontSize: "20px" }} />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={groupCount}
          page={page + 1}
          size="small"
          onChange={handlePageChange}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
        <br />
        <Grid container spacing={2}>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              type="text"
              value={groupName.name}
              label="Type a new group name"
              error={groupNameError?.name ? true : false}
              helperText={groupNameError?.name}
              onChange={(e) => {
                setGroupName({ ...groupName, name: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GroupAddOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={(e) => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="submitButton"
              style={{
                backgroundColor: "#3d80a8",
              }}
              variant="contained"
              disabled={loading}
              startIcon={<GroupIcon />}
              onClick={(e) => {
                handleSubmit(groupName);
              }}
            >
              Add group
            </Button>
          </Grid>
        </Grid>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Snackbar
          open={openSuccessSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSuccessSnackbar()}
        >
          <Alert
            onClose={() => setOpenSuccessSnackbar()}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Group created successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openDeleteSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenDeleteSnackbar()}
        >
          <Alert
            onClose={() => setOpenDeleteSnackbar()}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Group deleted successfully!
          </Alert>
        </Snackbar>
      </Container>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete group '${groupData.name}' from the database? You can't undo this!`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleClose();
                handleRemove(groupData.id);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
