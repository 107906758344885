import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { GroupRepository } from "../../repositories/GroupRepository";
import { useNavigate, useParams } from "react-router-dom";
import { UsersRepository } from "../../repositories/UsersRepository";
import { RoleRepository } from "../../repositories/RoleRepository";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const AddRoleToGroup = ({}) => {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();
  const [groupData, setGroupData] = useState({
    name: "",
    roles: [],
  });

  const leftSelectedItems = left.filter((item) => checked.includes(item.id));
  const rightSelectedItems = right.filter((item) => checked.includes(item.id));

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedRoleError, setSelectedRoleError] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();

  useEffect(() => {
    fetchGroupById(id);
  }, [id]);

  useEffect(() => {
    loadAllRoles();
  }, [groupData]);

  const loadAllRoles = () => {
    RoleRepository.fetchAllRoles()
      .then((res) => {
        const allRoles = res.data.content;
        const groupRoles = groupData?.roles || [];
        const filteredRoles = allRoles.filter((role) => {
          return !groupRoles.some((groupRole) => groupRole.id === role.id);
        });
        setLeft(filteredRoles);
        setRight(groupRoles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchGroupById = (id) => {
    GroupRepository.fetchGroupById(id)
      .then((res) => {
        setGroupData(res.data);
        setRight(groupData?.roles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftSelectedItems));
    setLeft(not(left, leftSelectedItems));
    setChecked([]);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightSelectedItems));
    setRight(not(right, rightSelectedItems));
    setChecked([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    <Paper sx={{ width: 250, height: 250, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  const handleSave = () => {
    setErrorMessage();
    setSelectedRoleError();
    setLoading(true);
    RoleRepository.AddRolesToGroup(id, right)
      .then((res) => {
        console.log(res);
        setOpenSuccessSnackbar(true);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
        if (err?.response?.data?.errors) {
          let errorsObj = [];
          err?.response?.data?.errors?.forEach((error) => {
            errorsObj[error.field] = error?.defaultMessage;
          });
          setSelectedRoleError(errorsObj);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className="wrapper"
        style={{
          color: "black",
          marginTop: "20px",
          marginLeft: "25%",
          width: "50%",
        }}
      >
        <Grid item xs={12} mx={25}>
          <h3>Add Roles for Group: {groupData?.name}</h3>
        </Grid>
        <Grid item>
          <h3>Available roles:</h3>
          {customList(left)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={checked.length === 0 || leftSelectedItems.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={checked.length === 0 || rightSelectedItems.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <h3>Selected roles:</h3>
          {customList(right)}
        </Grid>
        <Grid item xs={12}>
          <Grid container alignContent={"center"} justifyContent={"center"}>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
              style={{
                marginRight: "15px",
              }}
            >
              Back
            </Button>
            <Button
              style={{
                backgroundColor: "#3d80a8",
              }}
              variant="contained"
              disabled={loading}
              onClick={() => {
                const res = handleSave();
                if (res) {
                  navigate(-1);
                }
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage} </Alert>
          </Grid>
        )}
      </Grid>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Group added successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
