import {
  Container,
  Grid,
  TextField,
  Alert,
  Snackbar,
  CircularProgress,
  Button,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Input,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BugReportIcon from "@mui/icons-material/BugReport";
import { TicketRepository } from "../../repositories/TicketRepository";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Backup } from "@mui/icons-material";

export const AddTicket = ({}) => {
  const [formData, setFormData] = useState({
    description: "",
    type: "",
    status: "",
    priority: "",
  });
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();

  const [types, setTypes] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    document.title = "Add Ticket | Ticketing System";
  }, []);

  useEffect(() => {
    loadAllTypes();
    loadAllPriorities();
    loadAllStatuses();
  }, []);

  useEffect(() => {
    const defaultType = types.find((type) => type.typeName === "Help");
    const defaultPriority = priorities.find(
      (priority) => priority.priorityName === "Medium"
    );
    const defaultStatus = statuses.find(
      (status) => status.statusName === "Opened"
    );

    setFormData({
      ...formData,
      type: defaultType,
      priority: defaultPriority,
      status: defaultStatus,
    });
  }, [types, priorities, statuses]);

  const loadAllTypes = () => {
    TicketRepository.fetchAllTicketTypes()
      .then((res) => {
        setTypes(res.data);
        console.log(types);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loadAllPriorities = () => {
    TicketRepository.fetchAllTicketPriorities()
      .then((res) => {
        setPriorities(res.data);
        console.log(priorities);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loadAllStatuses = () => {
    TicketRepository.fetchAllTicketStatuses()
      .then((res) => {
        setStatuses(res.data);
        console.log(statuses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (ticket) => {
    setErrorMessage();
    setFormDataErrors();
    setLoading(true);

    if (ticket.type.typeName == "" || ticket.priority.priorityName == "") {
      setErrorMessage("Please select a type and priority for the ticket.");
      setLoading(false);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append(
      "request",
      new Blob([JSON.stringify(ticket)], { type: "application/json" })
    );
    files.forEach((file, index) => {
      formDataToSend.append(`files`, file);
    });

    TicketRepository.createTicket(formDataToSend)
      .then((res) => {
        console.log(res);
        setOpenSuccessSnackbar(true);
        setFormData({ ...formData, description: "" });
        clearFileInput();
      })
      .catch((err) => {
        console.log(formData);
        console.log(err);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
        if (err?.response?.data?.errors) {
          let errorsObj = [];
          err?.response?.data?.errors?.forEach((error) => {
            errorsObj[error.field] = error?.defaultMessage;
          });
          setFormDataErrors(errorsObj);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setFiles([]);
    }
  };

  return (
    <>
      <Container style={{ fontFamily: "Montserrat", marginTop: "30px" }}>
        <Grid container spacing={2} p={{ xs: 1 }} className="wrapper">
          <h1 style={{ color: "#3d80a8", marginLeft: "15px" }}>
            Create Ticket
          </h1>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              size="medium"
              minRows={2}
              type="text"
              value={formData?.description}
              label="Description"
              error={formDataErrors?.description ? true : false}
              helperText={formDataErrors?.description}
              onChange={(e) => {
                setFormData({ ...formData, description: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DescriptionOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              style={{ width: "50%" }}
              labelId="type-label"
              id="type-select"
              value={formData?.type}
              label="Type"
              onChange={(event) => {
                setFormData({ ...formData, type: event.target.value });
              }}
            >
              {types.map((type) => (
                <MenuItem key={type.id} value={type}>
                  {type.typeName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <InputLabel id="priority-label">Priority</InputLabel>
            <Select
              style={{ width: "50%" }}
              labelId="priority-label"
              id="priority-select"
              value={formData?.priority}
              label="Priority"
              onChange={(event) => {
                setFormData({
                  ...formData,
                  priority: event.target.value,
                });
              }}
            >
              {priorities.map((priority) => (
                <MenuItem key={priority.id} value={priority}>
                  {priority.priorityName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              marginLeft: "-15px",
            }}
          >
            <Box>
              <Button
                style={{
                  width: "250px",
                  height: "55px",
                  textTransform: "none",
                  fontSize: "15px",
                }}
                variant="contained"
                color="primary"
                startIcon={<Backup />}
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                {files.length > 0
                  ? files[0].name.slice(0, 15) + "..."
                  : "Choose Files"}
                <input
                  type="file"
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Button>
              <Grid
                item
                style={{
                  color: "gray",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <label style={{ fontSize: "14px", textAlign: "center" }}>
                  Max upload size: 5MB
                </label>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "-15px",
            }}
          >
            <Button
              onClick={clearFileInput}
              size="small"
              style={{
                display: files ? "flex" : "none",
              }}
            >
              Clear
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="submitButton"
              style={{
                backgroundColor: "#3d80a8",
              }}
              variant="contained"
              disabled={loading}
              startIcon={<BugReportIcon />}
              onClick={(e) => {
                handleSubmit(formData);
              }}
            >
              Create Ticket
            </Button>
          </Grid>
        </Grid>
      </Container>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Ticket created successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
