import {
  Grid,
  TextField,
  Button,
  Container,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UsersRepository } from "../../repositories/UsersRepository";
import { useNavigate } from "react-router-dom";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PasswordIcon from "@mui/icons-material/Password";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import { GroupRepository } from "../../repositories/GroupRepository";
import "../../App.css";
import {
  Person,
  PersonOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const AddUser = ({}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
    phoneNumber: "",
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedGroupError, setSelectedGroupError] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const leftSelectedItems = left.filter((item) => checked.includes(item.id));
  const rightSelectedItems = right.filter((item) => checked.includes(item.id));

  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });

  useEffect(() => {
    document.title = "Add User | Ticketing System";
  }, []);

  useEffect(() => {
    loadAllGroups();
  }, []);

  const loadAllGroups = () => {
    GroupRepository.fetchAllGroups()
      .then((res) => {
        setLeft(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (user) => {
    setErrorMessage();
    setFormDataErrors();
    setLoading(true);
    UsersRepository.createUser(user)
      .then((res) => {
        console.log(res);
        const userId = res.data.id;
        console.log(right);
        GroupRepository.AddGroupsToUser(userId, right)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            if (err?.response?.data?.message) {
              setErrorMessage(err?.response?.data?.message);
            }
            if (err?.response?.data?.errors) {
              let errorsObj = [];
              err?.response?.data?.errors?.forEach((error) => {
                errorsObj[error.field] = error?.defaultMessage;
              });
              setSelectedGroupError(errorsObj);
            }
          })
          .finally(() => {
            setLoading(false);
          });
        setOpenSuccessSnackbar(true);
        setFormData({
          id: null,
          firstName: "",
          lastName: "",
          username: "",
          password: "",
          email: "",
          phoneNumber: "",
        });
      })
      .catch((err) => {
        console.log(formData);
        console.log(err);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
        if (err?.response?.data?.errors) {
          let errorsObj = [];
          err?.response?.data?.errors?.forEach((error) => {
            errorsObj[error.field] = error?.defaultMessage;
          });
          setFormDataErrors(errorsObj);
        }
      })
      .finally(() => {
        setLoading(false);
        setRight([]);
        loadAllGroups();
      });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftSelectedItems));
    setLeft(not(left, leftSelectedItems));
    setChecked([]);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightSelectedItems));
    setRight(not(right, rightSelectedItems));
    setChecked([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const customList = (items) => (
    <Paper sx={{ width: 250, height: 250, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <>
      <Container>
        <h1 style={{ fontFamily: "Montserrat" }}>Create User Form:</h1>
        <Grid
          container
          spacing={2}
          p={{ xs: 1 }}
          className="wrapper"
          style={{ width: "100%" }}
        >
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              type="text"
              value={formData?.username}
              label="Username"
              error={formDataErrors?.username ? true : false}
              helperText={formDataErrors?.username}
              onChange={(e) => {
                setFormData({ ...formData, username: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              type={passwordValues.showPassword ? "text" : "password"}
              value={formData?.password}
              label="Password"
              error={formDataErrors?.password ? true : false}
              helperText={formDataErrors?.password}
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              type="text"
              value={formData?.firstName}
              label="First name"
              error={formDataErrors?.firstName ? true : false}
              helperText={formDataErrors?.firstName}
              onChange={(e) => {
                setFormData({ ...formData, firstName: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              type="text"
              value={formData?.lastName}
              label="Last name"
              error={formDataErrors?.lastName ? true : false}
              helperText={formDataErrors?.lastName}
              onChange={(e) => {
                setFormData({ ...formData, lastName: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              value={formData?.email}
              label="Email"
              error={formDataErrors?.email ? true : false}
              helperText={formDataErrors?.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              value={formData?.phoneNumber}
              label="Phone number"
              error={formDataErrors?.phoneNumber ? true : false}
              helperText={formDataErrors?.phoneNumber}
              onChange={(e) => {
                setFormData({ ...formData, phoneNumber: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneEnabledOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              style={{ color: "black" }}
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <h3>Available groups:</h3>
                {customList(left)}
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={
                      checked.length === 0 || leftSelectedItems.length === 0
                    }
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={
                      checked.length === 0 || rightSelectedItems.length === 0
                    }
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <h3>Selected groups:</h3>
                {customList(right)}
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="submitButton"
              variant="contained"
              style={{
                backgroundColor: "#3d80a8",
              }}
              disabled={loading}
              startIcon={<PersonAddAltIcon />}
              onClick={(e) => {
                console.log(right);
                handleSubmit(formData, right);
              }}
            >
              Add user
            </Button>
          </Grid>
        </Grid>
      </Container>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          User created successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
