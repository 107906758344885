import {
  TableCell,
  TableRow,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Backdrop,
  CircularProgress,
  Grid,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";

import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { UserRevisions } from "./UserRevisions";

export const User = ({ data, handleRemove, handleEdit }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phoneNumber: "",
  });
  const [revisionsOpen, setRevisionsOpen] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const isUserAuthorized = () => {
    const decodedToken = jwtDecode(auth.token);

    const roles = decodedToken.roles;
    return roles.includes("ROLE_SUPERVISION");
  };

  const formatPhoneNumber = (phoneNumber) => {
    let operatorCode = phoneNumber.substring(0, 3);
    const firstPart = phoneNumber.substring(3, 6);
    const secondPart = phoneNumber.substring(6);

    return `${operatorCode} ${firstPart} ${secondPart}`;
  };

  const handleClickRevisionsOpen = () => {
    setRevisionsOpen(true);
  };
  const handleClickRevisionsClose = () => {
    setRevisionsOpen(false);
  };

  return (
    <>
      <TableRow
        key={data?.id}
        style={{
          background: data?.groups.some((group) =>
            group.roles.some((role) => role.name === "SUPERVISION")
          )
            ? "#f5f9ff"
            : "none",
        }}
      >
        <TableCell style={{ minWidth: 100, textAlign: "center" }}>
          <Tooltip
            TransitionComponent={Zoom}
            title={
              data?.groups.some((group) =>
                group.roles.some((role) => role.name === "SUPERVISION")
              )
                ? auth.user === data?.username
                  ? "You"
                  : "Supervisor"
                : "User"
            }
          >
            {data?.username}
          </Tooltip>
        </TableCell>
        <TableCell style={{ minWidth: 120, textAlign: "center" }}>
          {data?.firstName}
        </TableCell>
        <TableCell style={{ minWidth: 120, textAlign: "center" }}>
          {data?.lastName}
        </TableCell>
        <TableCell style={{ minWidth: 200, textAlign: "center" }}>
          {data?.email}
        </TableCell>
        <TableCell style={{ textAlign: "center", minWidth: 150 }}>
          {formatPhoneNumber(data?.phoneNumber)}
        </TableCell>
        <TableCell>
          <Box justifyContent={"space-between"} display={"flex"}>
            <div style={{ margin: "0 8px" }}>
              <Button
                style={{ width: "150px", borderRadius: "20px" }}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => {
                  navigate(`/users/${data?.id}`);
                }}
                startIcon={<DescriptionIcon />}
              >
                View data
              </Button>
            </div>
            <div style={{ margin: "0 8px" }}>
              <Button
                style={{
                  display: isUserAuthorized() ? "flex" : "none",
                  borderRadius: "20px",
                }}
                color="error"
                startIcon={<DeleteIcon />}
                variant="outlined"
                size="small"
                disabled={
                  loading ||
                  data?.groups.some((group) =>
                    group.roles.some((role) => role.name === "SUPERVISION")
                  )
                }
                onClick={() => {
                  setUserData({
                    id: data?.id,
                    username: data?.username,
                  });
                  setLoading(true);
                  handleClickOpen();
                }}
              >
                Remove
              </Button>
            </div>
            <div style={{ margin: "0 8px" }}>
              <Button
                style={{
                  display: isUserAuthorized() ? "flex" : "none",
                  borderRadius: "20px",
                }}
                variant="outlined"
                size="small"
                disabled={
                  data?.groups.some((group) =>
                    group.roles.some((role) => role.name === "SUPERVISION")
                  ) && auth.user !== data?.username
                }
                startIcon={<EditIcon />}
                onClick={() => {
                  navigate(`/users/edit/${data?.id}`);
                }}
              >
                Edit
              </Button>
            </div>
            <div style={{ margin: "0 8px" }}>
              <Button
                style={{
                  display: isUserAuthorized() ? "flex" : "none",
                  borderRadius: "20px",
                }}
                variant="outlined"
                size="small"
                startIcon={<EditNoteOutlinedIcon />}
                onClick={handleClickRevisionsOpen}
              >
                Revisions
              </Button>
            </div>
          </Box>
        </TableCell>
      </TableRow>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete user: '${userData.username}' with id: '${userData.id}' from the database?`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleClose();
                handleRemove(userData.id);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {revisionsOpen && (
        <UserRevisions
          id={data.id}
          open={revisionsOpen}
          handleClose={handleClickRevisionsClose}
        />
      )}
    </>
  );
};
