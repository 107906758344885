import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";

export const ProfileListItems = ({ handleRefreshToken }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [tokenExpiration, setTokenExpiration] = React.useState(null);
  const [timer, setTimer] = useState(0);

  const formatTime = (milliseconds) => {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  React.useEffect(() => {
    let tokenData;
    if (auth?.token) {
      tokenData = jwtDecode(localStorage.getItem("token"));
      setTokenExpiration(tokenData?.exp * 1000 - new Date().getTime());
    }
    setTimer(
      setInterval(() => {
        setTokenExpiration(tokenData?.exp * 1000 - new Date().getTime());
      }, 1000)
    );

    return () => {
      setTimer(0);
      clearInterval(timer);
    };
  }, [auth?.token]);

  return (
    <React.Fragment>
      <ListSubheader component="div">Profile</ListSubheader>
      <ListItemButton
        onClick={() => {
          navigate(`/users/myProfile`);
        }}
      >
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="My Profile" />
      </ListItemButton>

      <ListItemButton
        onClick={() => {
          handleRefreshToken();
          setTokenExpiration(10 * 60 * 60 * 1000);
        }}
      >
        <ListItemIcon>
          <PublishedWithChangesIcon />
        </ListItemIcon>
        <ListItemText
          primary="Refresh Token"
          secondary={formatTime(tokenExpiration)}
        />
      </ListItemButton>
    </React.Fragment>
  );
};
