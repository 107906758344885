import React, { useEffect, useState } from "react";
import { Grid, Button, Container, Tooltip, Divider } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScheduleIcon from "@mui/icons-material/Schedule";
import UpdateIcon from "@mui/icons-material/Update";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useParams, Link, useNavigate } from "react-router-dom";
import { UsersRepository } from "../../repositories/UsersRepository";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import moment from "moment";
import { UserRevisions } from "./UserRevisions";
export const MyProfile = ({}) => {
  const auth = useAuth();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phoneNumber: "",
    isActive: "",
    dateCreated: "",
    lastModified: "",
    createdBy: "",
    modifiedBy: "",
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    document.title = "My Profile | Ticketing System";
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const isUserAuthorized = () => {
    const decodedToken = jwtDecode(auth.token);

    const roles = decodedToken.roles;
    const allowedRoles = ["ROLE_SUPERVISION"];
    return roles.some((role) => allowedRoles.includes(role));
  };

  const fetchCurrentUser = () => {
    UsersRepository.fetchCurrentUser()
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const decodedToken = jwtDecode(auth.token);
  const role = decodedToken.roles;

  const formatPhoneNumber = (phoneNumber) => {
    let operatorCode = phoneNumber.substring(0, 3);
    const firstPart = phoneNumber.substring(3, 6);
    const secondPart = phoneNumber.substring(6);

    return `${operatorCode} ${firstPart} ${secondPart}`;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container
        className="wrapper"
        style={{
          color: "black",
          width: "60%",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Grid
          container
          spacing={2}
          p={3}
          sx={{ fontWeight: "light", fontSize: "1.5em" }}
        >
          <Grid
            item
            xs={12}
            style={{
              color: "#3162b0",

              marginTop: "-5px",
              marginBottom: "50px",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <h2
                style={{
                  color: "#3d80a8",
                  fontFamily: "Montserrat",
                  marginLeft: "10px",
                }}
              >
                {auth.user}{" "}
                <AccountCircleOutlinedIcon
                  fontSize="large"
                  style={{ position: "relative", top: "5px" }}
                />
              </h2>
              <Grid item>
                <div
                  style={{
                    display: "flex",
                    fontSize: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  <Tooltip title="Date Created">
                    <ScheduleIcon style={{ marginTop: "5px" }} />{" "}
                    <b>
                      <span style={{ color: "#3162b0" }}>
                        {" "}
                        {moment(userData?.dateCreated).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}{" "}
                      </span>
                    </b>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: userData.lastModified !== null ? "flex" : "none",
                    fontSize: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  <Tooltip title="Last Modified">
                    <UpdateIcon
                      style={{
                        fontSize: "27px",
                        color: "green",
                      }}
                    />{" "}
                    <b>
                      <span
                        style={{
                          color: "green",
                          position: "absolute",
                          marginTop: "5px",
                        }}
                      >
                        {userData.lastModified &&
                          moment(userData?.lastModified).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                      </span>
                    </b>
                  </Tooltip>
                </div>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            style={{
              margin: "10px",
              width: "200px",
              display: isUserAuthorized() ? "flex" : "none",
            }}
            onClick={handleClickOpen}
            startIcon={<EditNoteOutlinedIcon />}
          >
            Revisions
          </Button>
          <TableContainer
            component={Paper}
            style={{
              marginBottom: "50px",
              boxShadow: "0px 5px 14px rgba(0, 0, 0, 0.5)",
              borderRadius: "8px",
            }}
          >
            <Table sx={{ fontSize: "small" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Full Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"> </TableCell>
                  <TableCell align="left">
                    {userData.firstName} {userData.lastName}
                  </TableCell>
                  <TableCell align="left"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Username</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"> </TableCell>
                  <TableCell align="left">{userData.username}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow xs={3}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Email</b>
                  </TableCell>

                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">{userData.email}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">
                    {formatPhoneNumber(userData.phoneNumber)}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Created By</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">{userData?.createdBy}</TableCell>

                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow
                  style={{
                    display:
                      userData?.modifiedBy != null ? "table-row" : "none",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Modified By</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>

                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">{userData?.modifiedBy}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row "
                    style={{ color: "#5894b1" }}
                  >
                    <b>Groups (roles)</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">
                    {userData?.groups?.map((group) => (
                      <li>
                        {group.name}
                        {" ("}
                        {group?.roles?.map((role) => role.name).join(", ")}
                        {")"}
                      </li>
                    ))}
                  </TableCell>

                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  style={{ width: "200px" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ width: "200px" }}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    navigate(`/users/myProfile/edit`);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {open && (
        <UserRevisions id={userData.id} open={open} handleClose={handleClose} />
      )}
    </>
  );
};
