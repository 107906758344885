import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Grid,
  Button,
  Container,
  Tooltip,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AuditRepository } from "../../repositories/AuditRepository";
import { ArrowBack, CloseOutlined } from "@mui/icons-material";

export const UserRevisions = ({ id, open, handleClose }) => {
  const [viewRevision, setViewRevision] = useState();
  const [revisionsData, setRevisionsData] = useState([{}]);
  const [revisionData, setRevisionData] = useState({});
  const [revisionDataToCompare, setRevisionDataToCompare] = useState({});
  const [revisionNumber, setRevisionNumber] = useState();
  const [revisionNumberToCompare, setRevisionNumberToCompare] = useState();

  useEffect(() => {
    fetchUserRevisions();
  }, [open]);

  useEffect(() => {
    if (revisionNumber != null && revisionNumberToCompare != null) {
      fetchUserRevisionsToCompare(revisionNumber, revisionNumberToCompare);
    }
  }, [revisionNumber, revisionNumberToCompare]);

  const fetchUserRevisions = () => {
    AuditRepository.fetchUserRevisions(id)
      .then((res) => {
        setRevisionsData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUserRevisionByNumber = (revisionNumber) => {
    AuditRepository.fetchUserRevisionByNumber(revisionNumber)
      .then((res) => {
        setRevisionData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUserRevisionsToCompare = (
    revisionNumber,
    revisionNumberToCompare
  ) => {
    AuditRepository.fetchUserRevisionsToCompare(
      revisionNumber,
      revisionNumberToCompare
    ).then((res) => {
      console.log(res);
      setRevisionData(res?.data?.firstRevision);
      setRevisionDataToCompare(res?.data?.secondRevision);
    });
  };

  const compareIfEqual = (arr1, arr2) => {
    if (arr1 === arr2) return true;
    if (arr1 == null || arr2 == null) return false;
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; ++i) {
      if (!objectsEqual(arr1[i], arr2[i])) return false;
    }
    return true;
  };
  const objectsEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;
    if (obj1 == null || obj2 == null) return false;
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) return false;
    }
    return true;
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="revisions-dialog-title"
        aria-describedby="revisions-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "30px",
          },
          backdropFilter: "blur(2px) sepia(1%)",
          background: "transparent",
        }}
      >
        <DialogContent
          style={{
            paddingBottom: "50px",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "16px",
            }}
          >
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                margin: "8px",
                display: viewRevision ? "flex" : "none",
              }}
              onClick={() => {
                setViewRevision(false);
              }}
            >
              <ArrowBack />
            </IconButton>
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: "8px",
              }}
              onClick={() => {
                setViewRevision(false);
                handleClose();
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
          {!viewRevision ? (
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Montserrat",
                  }}
                >
                  <h2>User Revisions</h2>
                  <TableContainer style={{ maxHeight: "600px" }}>
                    <Table
                      style={{
                        position: "relative",
                      }}
                      className="wrapper"
                    >
                      <TableHead
                        style={{
                          background: "#3d80a8",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        <TableRow>
                          <TableCell style={{ color: "white" }}>
                            Revision number
                          </TableCell>
                          <TableCell
                            style={{ color: "white", textAlign: "center" }}
                          >
                            Date of revision
                          </TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {revisionsData && revisionsData.length > 0 ? (
                          revisionsData?.map((revision, index) => (
                            <TableRow>
                              <TableCell>{revision[3]}</TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {moment(revision[4]).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    setRevisionNumber(revision[3]);
                                    if (index < revisionsData.length - 1) {
                                      setRevisionNumberToCompare(
                                        revisionsData[index + 1][3]
                                      );
                                    } else {
                                      setRevisionNumberToCompare(revision[3]);
                                    }
                                    console.log(revisionNumber);
                                    console.log(revisionNumberToCompare);
                                    setViewRevision(true);
                                  }}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell>No revisions available!</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2
                style={{
                  fontFamily: "Montserrat",
                }}
              >
                Compare revisions
              </h2>
              <TableContainer>
                <Table className="wrapper">
                  <TableHead style={{ background: "#3d80a8" }}>
                    <TableRow>
                      <TableCell style={{ color: "white" }}>
                        <b>Revision number</b>
                      </TableCell>

                      <TableCell
                        style={{
                          color: "white",
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        <b>{revisionNumberToCompare}</b>
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        <b>{revisionNumber}</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <label>User ID</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.id}
                      </TableCell>
                      <TableCell>{revisionData?.id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>First name</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.firstName}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.firstName ===
                            revisionDataToCompare?.firstName
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.firstName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>Last name</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.lastName}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.lastName ===
                            revisionDataToCompare?.lastName
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.lastName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>Username</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.username}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.username ===
                            revisionDataToCompare?.username
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.username}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>Email</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.email}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.email === revisionDataToCompare?.email
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.email}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>Phone number</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.phoneNumber}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.phoneNumber ===
                            revisionDataToCompare?.phoneNumber
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.phoneNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>Date created</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {moment(revisionDataToCompare?.dateCreated).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.dateCreated ===
                            revisionDataToCompare?.dateCreated
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {moment(revisionData?.dateCreated).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>Last modified</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.lastModified
                          ? moment(revisionDataToCompare?.lastModified).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.lastModified ===
                            revisionDataToCompare?.lastModified
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.lastModified
                          ? moment(revisionData?.lastModified).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>Created by</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.createdBy}
                      </TableCell>
                      <TableCell>{revisionData?.createdBy}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label>Modified by</label>
                      </TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.modifiedBy}
                      </TableCell>
                      <TableCell
                        style={{
                          background:
                            revisionData?.modifiedBy ===
                            revisionDataToCompare?.modifiedBy
                              ? ""
                              : "#f1f1f1",
                        }}
                      >
                        {revisionData?.modifiedBy}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Groups</TableCell>

                      <TableCell
                        style={{
                          display:
                            revisionNumber === revisionNumberToCompare
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {revisionDataToCompare?.groups?.map((group) => (
                          <li>{group.name}</li>
                        ))}
                      </TableCell>
                      <TableCell
                        style={{
                          background: compareIfEqual(
                            revisionData?.groups,
                            revisionDataToCompare?.groups
                          )
                            ? ""
                            : "#f1f1f1",
                        }}
                      >
                        {revisionData?.groups?.map((group) => (
                          <li>{group.name}</li>
                        ))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <span style={{ fontSize: "13px", fontFamily: "Montserrat" }}>
                  *Changes are highlighted
                </span>
              </TableContainer>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
