import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  Button,
  TextField,
  Grid,
  Container,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { UsersRepository } from "../../repositories/UsersRepository";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const ResetPassword = ({}) => {
  const [formData, setFormData] = useState();
  const navigate = useNavigate();
  const auth = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();

  const [oldValues, setOldValues] = useState({
    oldPassword: "",
    showOldPassword: false,
  });

  const [newValues, setNewValues] = useState({
    newPassword: "",
    showNewPassword: false,
  });

  useEffect(() => {
    document.title = "Change Password | Ticketing System";
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    UsersRepository.fetchCurrentUser()
      .then((res) => {
        setFormData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setErrorMessage();
    setFormDataErrors();
    setLoading(true);
    if (formData.oldPassword === formData.newPassword) {
      setErrorMessage(
        "Please provide a new password different from the current one!"
      );
      setLoading(false);
      return;
    }
    UsersRepository.updatePassword(formData)
      .then((res) => {
        setOpenSuccessSnackbar(true);
        loadUser();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
        if (err?.response?.data?.errors) {
          let errorsObj = [];
          err?.response?.data?.errors?.forEach((error) => {
            errorsObj[error.field] = error?.defaultMessage;
          });
          setFormDataErrors(errorsObj);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickShowOldPassword = () => {
    setOldValues({
      ...oldValues,
      showOldPassword: !oldValues.showOldPassword,
    });
  };
  const handleClickShowNewPassword = () => {
    setNewValues({
      ...newValues,
      showNewPassword: !newValues.showNewPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <Grid
        container
        spacing={3}
        className="wrapper"
        style={{
          width: "50%",
          marginLeft: "280px",
          paddingLeft: "20px",
          marginTop: "40px",
        }}
      >
        <h1
          style={{
            fontFamily: "Montserrat",
            marginBottom: "40px",
            marginLeft: "80px",
            textAlign: "center",
            color: "#3d80a8",
          }}
        >
          Change your password
        </h1>
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            id="username"
            name="username"
            label="Username"
            value={formData?.username ?? ""}
            error={formDataErrors?.username ? true : false}
            helperText={formDataErrors?.username}
            type="text"
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            id="oldPassword"
            name="oldPassword"
            type={oldValues.showOldPassword ? "text" : "password"}
            label="Current password"
            value={formData?.oldPassword ?? ""}
            error={formDataErrors?.oldPassword ? true : false}
            helperText={formDataErrors?.oldPassword}
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {oldValues.showOldPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            id="newPassword"
            name="newPassword"
            type={newValues.showNewPassword ? "text" : "password"}
            label="New password"
            value={formData?.newPassword ?? ""}
            error={formDataErrors?.newPassword ? true : false}
            helperText={formDataErrors?.newPassword}
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {newValues.showNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item>
          <Button
            className="button-30"
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            style={{
              backgroundColor: "#3d80a8",
            }}
            disabled={loading}
            onClick={(e) => {
              handleEdit();
            }}
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Password changed successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};
