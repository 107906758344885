import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "block",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <h2>404 - Page Not Found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
