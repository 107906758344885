import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  Button,
  TextField,
  Grid,
  Container,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { UsersRepository } from "../../repositories/UsersRepository";
import { Link, useNavigate } from "react-router-dom";
import { TicketRepository } from "../../repositories/TicketRepository";

export const EditTicket = ({}) => {
  const { id } = useParams();
  const [ticketData, setTicketData] = useState({
    description: "",
    type: {
      typesName: "",
    },
    status: {
      statusName: "",
    },
    priority: {
      priorityName: "",
    },
  });

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();

  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    if (id) {
      loadTicket();
    }
  }, [id]);

  useEffect(() => {
    loadAllStatuses();
  }, []);

  const loadAllStatuses = () => {
    TicketRepository.fetchAllTicketStatuses()
      .then((res) => {
        setStatuses(res.data);
        console.log(statuses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadTicket = () => {
    TicketRepository.fetchTicketById(id)
      .then((res) => {
        setTicketData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setTicketData({
      ...ticketData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setErrorMessage();
    setFormDataErrors();
    setLoading(true);
    TicketRepository.updateTicketData(ticketData)
      .then((res) => {
        setOpenSuccessSnackbar(true);
        loadTicket();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
        if (err?.response?.data?.errors) {
          let errorsObj = [];
          err?.response?.data?.errors?.forEach((error) => {
            errorsObj[error.field] = error?.defaultMessage;
          });
          setFormDataErrors(errorsObj);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Grid
        container
        spacing={2}
        className="wrapper"
        style={{ width: "100%", marginTop: "20px" }}
      >
        <Grid item xs={12}>
          <h1 style={{ color: "#3d80a8" }}>Edit Ticket Status</h1>
        </Grid>
        <h2 style={{ color: "black", marginLeft: "15px" }}>
          Ticket ID: {ticketData?.id}
        </h2>
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            style={{ width: "50%" }}
            labelId="status-label"
            id="status"
            value={ticketData?.status}
            error={formDataErrors?.status?.statusName ? true : false}
            helperText={formDataErrors?.status?.statusName}
            onChange={(e) =>
              setTicketData({ ...ticketData, status: e.target.value })
            }
          >
            {statuses.map((status) => (
              <MenuItem key={status.id} value={status}>
                {status.statusName}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item>
          <Button
            className="button-30"
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            style={{
              backgroundColor: "#3d80a8",
            }}
            disabled={loading}
            onClick={(e) => {
              handleEdit();
            }}
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Ticket edited successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};
