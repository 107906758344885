import axios from "axios";
import Config from "../config";
const { baseURL } = Config.url;

export const TicketRepository = {
  createTicket: (ticket) => {
    return axios({
      url: `${Config.url}/tickets`,
      method: "POST",
      data: ticket,
    });
  },
  fetchTicketById: (id) => {
    return axios({
      url: `${Config.url}/tickets/${id}`,
      method: "GET",
    });
  },
  fetchAllTickets: (page, rowsPerPage, searchCriteria) => {
    return axios({
      url: `${Config.url}/tickets/search`,
      method: "POST",
      data: searchCriteria,
      params: {
        page: page,
        size: rowsPerPage,
      },
    });
  },
  updateTicketData: (ticket) => {
    return axios({
      url: `${Config.url}/tickets`,
      method: "PUT",
      data: ticket,
    });
  },
  updateTicketAssignedAgent: (ticketId) => {
    return axios({
      url: `${Config.url}/tickets/assign`,
      method: "PUT",
      data: { id: ticketId },
    });
  },
  unassignTicketAgent: (ticketId) => {
    return axios({
      url: `${Config.url}/tickets/unassign`,
      method: "PUT",
      data: { id: ticketId },
    });
  },
  closeTicket: (id) => {
    return axios({
      url: `${Config.url}/tickets/close`,
      method: "PUT",
      data: {
        id: id,
      },
    });
  },
  fetchTicketsByTicketOwner: (ticketOwnerId, page, rowsPerPage) => {
    return axios({
      url: `${Config.url}/owners/${ticketOwnerId}`,
      method: "GET",
      data: [{}],
      params: {
        page: page,
        size: rowsPerPage,
      },
    });
  },
  fetchAllTicketTypes: () => {
    return axios({
      url: `${Config.url}/tickets/type`,
      method: "GET",
    });
  },
  fetchAllTicketPriorities: () => {
    return axios({
      url: `${Config.url}/tickets/priority`,
      method: "GET",
    });
  },
  fetchAllTicketStatuses: () => {
    return axios({
      url: `${Config.url}/tickets/status`,
      method: "GET",
    });
  },

  deleteTicket: (id) => {
    return axios({
      url: `${Config.url}/tickets/${id}`,
      method: "DELETE",
    });
  },
};
