import axios from "axios";
import Config from "../config";
const { baseURL } = Config.url;

export const AttachmentRepository = {
  fetchAttachmentByTicketId: (ticketId) => {
    return axios({
      url: `${Config.url}/attachments/download/${ticketId}`,
      method: "GET",
    });
  },

  downloadAttachment: async (id) => {
    return await axios({
      url: `${Config.url}/attachments/download/${id}`,
      method: "GET",
      responseType: "blob",
    });
  },
  downloadMultipleAttachments: async (attachmentIds) => {
    return await axios({
      url: `${Config.url}/attachments/downloadMultiple`,
      method: "POST",
      data: { attachmentIds: attachmentIds },
      responseType: "blob",
    });
  },
};
