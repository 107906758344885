import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Grid,
  Button,
  Container,
  Tooltip,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScheduleIcon from "@mui/icons-material/Schedule";
import UpdateIcon from "@mui/icons-material/Update";
import PortraitIcon from "@mui/icons-material/Portrait";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useParams, Link, useNavigate } from "react-router-dom";
import { UsersRepository } from "../../repositories/UsersRepository";
import EditIcon from "@mui/icons-material/Edit";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";
import "../../App.css";
import { AuditRepository } from "../../repositories/AuditRepository";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { UserRevisions } from "./UserRevisions";

export const UserProfile = ({}) => {
  const { id } = useParams();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phoneNumber: "",
    dateCreated: "",
    lastModified: "",
  });

  const [revisionsOpen, setRevisionsOpen] = useState(false);
  const auth = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "User Info | Ticketing System";
  }, []);

  useEffect(() => {
    fetchUserById(id);
  }, []);

  const fetchUserById = (id) => {
    UsersRepository.fetchUserById(id)
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickRevisionsOpen = () => {
    setRevisionsOpen(true);
  };
  const handleClickRevisionsClose = () => {
    setRevisionsOpen(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    let operatorCode = phoneNumber.substring(0, 3);
    const firstPart = phoneNumber.substring(3, 6);
    const secondPart = phoneNumber.substring(6);

    return `${operatorCode} ${firstPart} ${secondPart}`;
  };

  return (
    <>
      <Container
        className="wrapper"
        style={{
          color: "black",
          width: "60%",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Grid
          container
          spacing={2}
          p={3}
          sx={{ fontWeight: "light", fontSize: "1.5em" }}
        >
          <Grid
            item
            xs={12}
            style={{
              color: "#3162b0",

              marginTop: "-5px",
              marginBottom: "50px",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <h2 style={{ color: "#3d80a8" }}>
                {" "}
                USER INFO{" "}
                <AccountCircleOutlinedIcon
                  fontSize="large"
                  style={{ position: "relative", top: "5px" }}
                />
              </h2>
              <Grid item>
                <div style={{ display: "flex", fontSize: "16px" }}>
                  <Tooltip title="Date Created">
                    <ScheduleIcon
                      style={{ position: "relative", top: "5px" }}
                    />{" "}
                    <b>
                      <span style={{ color: "#3162b0" }}>
                        {" "}
                        {moment(userData?.dateCreated).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}{" "}
                      </span>
                    </b>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: userData.lastModified !== null ? "flex" : "none",
                    fontSize: "16px",
                  }}
                >
                  <Tooltip title="Last Modified">
                    <UpdateIcon
                      style={{
                        position: "relative",
                        top: "2px",
                        fontSize: "25px",
                        color: "green",
                      }}
                    />{" "}
                    <b>
                      <span
                        style={{
                          color: "green",
                          position: "absolute",
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {userData.lastModified &&
                          moment(userData?.lastModified).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                      </span>
                    </b>
                  </Tooltip>
                </div>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            style={{ margin: "10px", width: "200px" }}
            onClick={handleClickRevisionsOpen}
            startIcon={<EditNoteOutlinedIcon />}
          >
            Revisions
          </Button>

          <TableContainer
            component={Paper}
            style={{
              marginBottom: "50px",
              boxShadow: "0px 5px 14px rgba(0, 0, 0, 0.5)",
              borderRadius: "8px",
            }}
          >
            <Table sx={{ fontSize: "small" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Full Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"> </TableCell>
                  <TableCell align="left">
                    {userData.firstName} {userData.lastName}
                  </TableCell>
                  <TableCell align="left"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Username</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"> </TableCell>
                  <TableCell align="left">{userData.username}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow xs={3}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Email</b>
                  </TableCell>

                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">{userData.email}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">
                    {formatPhoneNumber(userData.phoneNumber)}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Created By</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">{userData?.createdBy}</TableCell>

                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow
                  style={{
                    display:
                      userData?.modifiedBy != null ? "table-row" : "none",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#5894b1" }}
                  >
                    <b>Modified By</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>

                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">{userData?.modifiedBy}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row "
                    style={{ color: "#5894b1" }}
                  >
                    <b>Groups (roles)</b>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRightAltIcon />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">
                    {userData?.groups?.map((group) => (
                      <li>
                        {group.name}
                        {" ("}
                        {group?.roles?.map((role) => role.name).join(", ")}
                        {")"}
                      </li>
                    ))}
                  </TableCell>

                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  style={{ width: "200px" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ width: "200px" }}
                  startIcon={<EditIcon />}
                  disabled={
                    userData?.groups?.some((group) =>
                      group?.roles?.some((role) => role.name === "SUPERVISION")
                    ) && auth.user !== userData?.username
                  }
                  onClick={() => {
                    navigate(`/users/edit/${id}`);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {revisionsOpen && (
        <UserRevisions
          id={userData.id}
          open={revisionsOpen}
          handleClose={handleClickRevisionsClose}
        />
      )}
    </>
  );
};
