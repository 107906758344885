import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Container,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Pagination,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { RoleRepository } from "../../repositories/RoleRepository";
import "../../App.css";
import { Clear, WorkspacesOutlined } from "@mui/icons-material";
export const AddRole = ({}) => {
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState();
  const [roleName, setRoleName] = useState({
    name: "",
  });
  const [roleData, setRoleData] = useState({
    id: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [roleNameError, setRoleNameError] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [roleCount, setRoleCount] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Roles | Ticketing System";
  }, []);

  useEffect(() => {
    loadAllRoles();
  }, [page, rowsPerPage]);

  const loadAllRoles = () => {
    RoleRepository.fetchAllRoles(page, rowsPerPage)
      .then((res) => {
        setRoles(res.data);
        setRoleCount(res.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (role) => {
    setErrorMessage();
    setRoleNameError();
    setLoading(true);
    RoleRepository.createRole(role)
      .then((res) => {
        setOpenSuccessSnackbar(true);
        setRoleName({ name: "" });
        loadAllRoles();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
        if (err?.response?.data?.errors) {
          let errorsObj = [];
          err?.response?.data?.errors?.forEach((error) => {
            errorsObj[error.field] = error?.defaultMessage;
          });
          setRoleNameError(errorsObj);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleRemove = (roleId) => {
    setErrorMessage();
    setLoading(true);
    RoleRepository.deleteRoleById(roleId)
      .then((res) => {
        console.log(res);
        setOpenDeleteSnackbar(true);
        loadAllRoles();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Container
        p={{ xs: 1 }}
        className="wrapper"
        style={{
          color: "black",
          marginTop: "20px",
          width: "30%",
          textAlign: "center",
        }}
      >
        <h2 style={{ fontFamily: "Montserrat", color: "#3d80a8" }}>
          Roles available{" "}
        </h2>
        <Grid container>
          {roles?.content?.map((role) => (
            <Grid
              item
              xs={12}
              className="wrapper-smaller"
              style={{
                margin: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={11} style={{ margin: "5px" }} key={role.name}>
                {role.name}
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Delete Role">
                  <Button
                    variant="text"
                    color="error"
                    disabled={loading}
                    onClick={() => {
                      setLoading(true);
                      setRoleData({
                        id: role.id,
                        name: role.name,
                      });
                      console.log(roleData);
                      handleClickOpen();
                    }}
                  >
                    <Clear style={{ fontSize: "20px" }} />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={roleCount}
          page={page + 1}
          size="small"
          onChange={handlePageChange}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
        <br />
        <Grid container spacing={2}>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              type="text"
              value={roleName.name}
              label="Role name"
              error={roleNameError?.name ? true : false}
              helperText={roleNameError?.name}
              onChange={(e) => {
                setRoleName({ ...roleName, name: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WorkspacesOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={(e) => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="submitButton"
              style={{
                backgroundColor: "#3d80a8",
              }}
              variant="contained"
              disabled={loading}
              startIcon={<WorkspacesIcon />}
              onClick={(e) => {
                handleSubmit(roleName);
              }}
            >
              Add role
            </Button>
          </Grid>
        </Grid>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Snackbar
          open={openSuccessSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSuccessSnackbar()}
        >
          <Alert
            onClose={() => setOpenSuccessSnackbar()}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Role created successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openDeleteSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenDeleteSnackbar()}
        >
          <Alert
            onClose={() => setOpenDeleteSnackbar()}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Role deleted successfully!
          </Alert>
        </Snackbar>
      </Container>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete role '${roleData.name}' from the database? You can't undo this!`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleClose();
                handleRemove(roleData.id);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
