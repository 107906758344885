import {
  Alert,
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Snackbar,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";
import CommentIcon from "@mui/icons-material/Comment";

export const AddComment = ({ ticketData, handleSubmit }) => {
  const [formData, setFormData] = useState({
    text: "",
    internalNote: false,
    ticketId: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    setFormData({
      ...formData,
      ticketId: ticketData?.id,
    });
  }, [formData]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "internalNote" ? e.target.checked : e.target.value,
    });
  };

  const isUserAuthorized = () => {
    const decodedToken = jwtDecode(auth.token);

    const roles = decodedToken.roles;
    return roles.includes("ROLE_SUPERVISION");
  };

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          p={{ xs: 1 }}
          className="wrapper"
          sx={{ color: "black", marginTop: "10px" }}
        >
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              size="medium"
              type="text"
              value={formData?.text || ""}
              label="Text..."
              error={formDataErrors?.text ? true : false}
              helperText={formDataErrors?.text}
              onChange={(e) => {
                setFormData({ ...formData, text: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CommentIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ color: "black" }}
              control={
                <Checkbox
                  checked={formData.internalNote}
                  onChange={handleChange}
                  name="internalNote"
                />
              }
              label="Internal Note"
            />
          </Grid>
          <Grid item>
            <Button
              id="submitButton"
              color="success"
              variant="outlined"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(formData);
                setFormData({
                  text: "",
                  internalNote: false,
                  ticketId: "",
                });
              }}
            >
              Add comment
            </Button>
          </Grid>
        </Grid>
      </Container>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Comment added successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
