import axios from "axios";
import Config from "../config";
const { baseURL } = Config.url;

export const RoleRepository = {
  createRole: (roleName) => {
    return axios({
      url: `${Config.url}/roles`,
      method: "POST",
      data: roleName,
    });
  },
  fetchAllRoles: (page, rowsPerPage) => {
    return axios({
      url: `${Config.url}/roles`,
      method: "GET",
      data: [{}],
      params: {
        page: page,
        size: 20,
      },
    });
  },
  AddRolesToGroup: (id, roles) => {
    return axios({
      url: `${Config.url}/roles/addGroup`,
      method: "POST",
      data: {
        groupId: id,
        roles: roles,
      },
    });
  },
  deleteRoleById: (roleId) => {
    return axios({
      url: `${Config.url}/roles/${roleId}`,
      method: "DELETE",
    });
  },
};
