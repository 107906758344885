import axios from "axios";
import Config from "../config";
const { baseURL } = Config.url;

export const CommentRepository = {
  addCommentToTicket: (comment) => {
    return axios({
      url: `${Config.url}/comments`,
      method: "POST",
      data: comment,
    });
  },
  fetchAllComments: (ticketId, page, rowsPerPage, searchCriteria) => {
    return axios({
      url: `${Config.url}/comments/search`,
      method: "POST",
      data: searchCriteria,
      params: {
        ticketId: ticketId,
        page: page,
        size: rowsPerPage,
      },
    });
  },
  addReplyToComment: (commentId, replyText) => {
    return axios({
      url: `${Config.url}/comments/${commentId}/reply`,
      method: "POST",
      data: {
        text: replyText,
      },
    });
  },
  deleteComment: (id) => {
    return axios({
      url: `${Config.url}/comments/${id}`,
      method: "DELETE",
    });
  },
};
