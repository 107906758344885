import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Snackbar,
  Alert,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Backdrop,
  CircularProgress,
  Tooltip,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TicketRepository } from "../../repositories/TicketRepository";
import { useAuth } from "../auth/AuthProvider";
import { Ticket } from "./Ticket";
import { jwtDecode } from "jwt-decode";
import { UsersRepository } from "../../repositories/UsersRepository";
import { Search } from "@mui/icons-material";

export const AssignedTickets = () => {
  const [tickets, setTickets] = useState();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState();
  const [filterByPriority, setFilterByPriority] = useState();
  const [filterByType, setFilterByType] = useState();
  const [filterByDescription, setFilterByDescription] = useState();
  const [filterByAgent, setFilterByAgent] = useState();
  const [clearFilterFlag, setClearFilterFlag] = useState(false);

  const auth = useAuth();

  const isUserAuthorized = () => {
    const decodedToken = jwtDecode(auth.token);

    const roles = decodedToken.roles;
    const allowedRoles = ["ROLE_SUPERVISION"];
    return roles.some((role) => allowedRoles.includes(role));
  };

  useEffect(() => {
    document.title = "Assigned Tickets | Ticketing System";
  }, []);

  useEffect(() => {
    loadAllTickets(page, rowsPerPage);
  }, [page, rowsPerPage, filterByStatus, filterByPriority, filterByType]);

  useEffect(() => {
    if (clearFilterFlag === true) {
      loadAllTickets();
    }
    setClearFilterFlag(false);
  }, [clearFilterFlag]);

  const loadAllTickets = () => {
    UsersRepository.fetchCurrentUser().then((response) => {
      let criteriaList = [
        !isUserAuthorized()
          ? {
              key: "assignedAgent.id",
              operation: "EQUALITY",
              value: response?.data?.id,
            }
          : {
              key: "assignedAgent",
              operation: "IS_NOT_NULL",
              value: true,
            },
        {
          key: "status.statusName",
          operation: "EQUALITY",
          value: filterByStatus,
        },
        {
          key: "priority.priorityName",
          operation: "EQUALITY",
          value: filterByPriority,
        },
        {
          key: "type.typeName",
          operation: "EQUALITY",
          value: filterByType,
        },
        {
          key: "description",
          operation: "CONTAINS",
          value: filterByDescription,
        },
        {
          key: "assignedAgent.username",
          operation: "CONTAINS",
          value: filterByAgent,
        },
      ];
      setLoading(true);
      TicketRepository.fetchAllTickets(page, rowsPerPage, criteriaList)
        .then((res) => {
          setTickets(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleClearFilter = () => {
    setFilterByPriority();
    setFilterByStatus();
    setFilterByType();
    setFilterByAgent();
    setFilterByDescription();
    setClearFilterFlag(true);
  };

  const handleReload = () => {
    loadAllTickets();
    setOpenSuccessSnackbar(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          color: "#3d80a8",
          fontFamily: "Montserrat",
        }}
      >
        Assigned Tickets
      </h1>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="medium"
            type="text"
            value={filterByDescription || ""}
            autoComplete={false}
            name="filter_by_description_field"
            label="Filter by description"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                loadAllTickets();
              }
            }}
            onChange={(event) => {
              if (event.target.value === "") {
                setFilterByDescription();
              } else {
                setFilterByDescription(event.target.value);
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{ display: isUserAuthorized() ? "flex" : "none" }}
        >
          <TextField
            fullWidth
            size="medium"
            type="text"
            value={filterByAgent || ""}
            autoComplete={false}
            name="filter_by_agent_filed"
            label="Filter by assigned agent"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                loadAllTickets();
              }
            }}
            onChange={(event) => {
              if (event.target.value === "") {
                setFilterByAgent();
              } else {
                setFilterByAgent(event.target.value);
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="filter-tickets-by-status-label">
              Filter by status
            </InputLabel>
            <Select
              labelId="filter-tickets-by-status-label"
              id="filter-tickets-by-status-select"
              value={filterByStatus || "All"}
              label="Filter by status"
              onChange={(event) => {
                if (event.target.value === "All") {
                  setFilterByStatus();
                } else {
                  setFilterByStatus(event.target.value);
                }
              }}
              defaultValue={"All"}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Opened">Opened</MenuItem>
              <MenuItem value="Processing">Processing</MenuItem>
              <MenuItem value="Closed">Closed</MenuItem>
              <MenuItem value="Resolved">Resolved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="filter-tickets-by-priority-label">
              Filter by priority
            </InputLabel>
            <Select
              labelId="filter-tickets-by-priority"
              id="filter-tickets-by-priority-select"
              value={filterByPriority || "All"}
              label="Filter by priority"
              onChange={(event) => {
                if (event.target.value === "All") {
                  setFilterByPriority();
                } else {
                  setFilterByPriority(event.target.value);
                }
              }}
              defaultValue={"All"}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Critical">Critical</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="filter-tickets-by-type-label">
              Filter by type
            </InputLabel>
            <Select
              labelId="filter-tickets-by-type"
              id="filter-tickets-by-type-select"
              value={filterByType || "All"}
              label="Filter by type"
              onChange={(event) => {
                if (event.target.value === "All") {
                  setFilterByType();
                } else {
                  setFilterByType(event.target.value);
                }
              }}
              defaultValue={"All"}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Help">Help</MenuItem>
              <MenuItem value="Bug">Bug</MenuItem>
              <MenuItem value="Incident">Incident</MenuItem>
              <MenuItem value="Request change">Request change</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100px",
          }}
        >
          <Tooltip title="Apply filtering">
            <Button
              style={{
                width: "100px",
              }}
              variant="outlined"
              color="inherit"
              onClick={() => {
                loadAllTickets();
              }}
            >
              <Search />
            </Button>
          </Tooltip>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            width: "100px",
          }}
        >
          <Tooltip title="Clear filtering">
            <Button
              style={{
                width: "100px",
              }}
              variant="outlined"
              color="inherit"
              onClick={() => {
                handleClearFilter();
              }}
            >
              Clear
            </Button>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <TablePagination
            component="div"
            count={tickets?.totalElements}
            rowsPerPageOptions={[4, 6, 10, 20]}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      {tickets && tickets?.content?.length !== 0 ? (
        <>
          <Grid
            container
            spacing={4}
            style={{ marginBottom: "20px" }}
            px={3}
            className="cards"
          >
            {tickets?.content.map((item) => (
              <Grid item xs={6} key={item.id}>
                <Ticket data={item} reloadTickets={handleReload} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <div></div>
      )}
      {loading && (
        <Backdrop
          sx={{
            color: "#fffff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" size={60} thickness={4} />
        </Backdrop>
      )}
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar()}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Action on ticket executed successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
