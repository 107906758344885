import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App, sum } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AddUser } from "./modules/people/AddUser";
import { UserProfile } from "./modules/people/UserProfile";
import { EditUser } from "./modules/people/EditUser";
import { AddGroup } from "./modules/people/AddGroup";
import { AddRole } from "./modules/people/AddRole";
import { AddUserToGroup } from "./modules/people/AddUserToGroup";
import { AddRoleToGroup } from "./modules/people/AddRoleToGroup";
import { Login } from "./modules/auth/Login";
import AuthProvider from "./modules/auth/AuthProvider";
import PrivateRoute from "./modules/auth/PrivateRoute";
import HttpInterceptors from "./common/HttpInterceptors";
import { Provider } from "react-redux";
import { Users } from "./modules/people/Users";
import store from "./store/Store";
import { AddTicket } from "./modules/tickets/AddTicket";
import { TicketView } from "./modules/tickets/TicketView";
import { EditTicket } from "./modules/tickets/EditTicket";
import { AddComment, Dashboard } from "@mui/icons-material";
import { ActiveTickets } from "./modules/tickets/ActiveTickets";
import { ClosedTickets } from "./modules/tickets/ClosedTickets";
import { AllTickets } from "./modules/tickets/AllTickets";
import { MyProfile } from "./modules/people/MyProfile";
import { EditMyProfile } from "./modules/people/EditMyProfile";
import { AssignedTickets } from "./modules/tickets/AssignedTickets";
import { ResetPassword } from "./modules/auth/ResetPassword";
import { UnassignedTickets } from "./modules/tickets/UnassignedTickets";
import { ForgotPassword } from "./modules/auth/ForgotPassword";
import { Home } from "./Home";
import NotFound from "./NotFound";

const root = ReactDOM.createRoot(document.getElementById("root"));

HttpInterceptors.setupInterceptors(store);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <App>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgotPassword/*" element={<ForgotPassword />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/users" element={<Users />} />
                <Route path="/tickets" element={<AllTickets />} />
                <Route path="/tickets/active" element={<ActiveTickets />} />
                <Route path="/tickets/closed" element={<ClosedTickets />} />
                <Route path="/tickets/assigned" element={<AssignedTickets />} />
                <Route
                  path="/tickets/unassigned"
                  element={<UnassignedTickets />}
                />
                <Route path="/users/add" element={<AddUser />} />
                <Route path="/users/:id" element={<UserProfile />} />
                <Route path="/users/edit/:id" element={<EditUser />} />
                <Route path="/groups/add" element={<AddGroup />} />
                <Route path="/roles/add" element={<AddRole />} />
                <Route path="/users/addGroups" element={<AddUserToGroup />} />

                <Route path="/users/myProfile" element={<MyProfile />} />
                <Route
                  path="/users/myProfile/edit"
                  element={<EditMyProfile />}
                />
                <Route
                  path="/users/myProfile/edit/resetPassword"
                  element={<ResetPassword />}
                />
                <Route
                  path="/users/edit/:id/addGroups"
                  element={<AddUserToGroup />}
                />
                <Route
                  path="/groups/add/:id/addRoles"
                  element={<AddRoleToGroup />}
                />
                <Route path="/tickets/create" element={<AddTicket />} />
                <Route path="/tickets/:id" element={<TicketView />} />
                <Route path="/tickets/:id/edit" element={<EditTicket />} />
                <Route
                  path="/tickets/:id/addComment"
                  element={<AddComment />}
                />
              </Route>
            </Routes>
          </App>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
