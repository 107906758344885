import axios from "axios";
import Config from "../config";
const { baseURL } = Config.url;

export const AuditRepository = {
  // Users
  fetchUserRevisions: (id) => {
    return axios({
      url: `${Config.url}/audit/user/revisions/${id}`,
      method: "GET",
    });
  },
  fetchUserRevisionByNumber: (revisionNumber) => {
    return axios({
      url: `${Config.url}/audit/user/revision/${revisionNumber}`,
      method: "GET",
    });
  },
  fetchUserRevisionsToCompare: (revisionNumber, revisionNumberToComapare) => {
    return axios({
      url: `${Config.url}/audit/user/compareRevisions`,
      method: "GET",
      params: {
        firstRevisionNumber: revisionNumber,
        secondRevisionNumber: revisionNumberToComapare,
      },
    });
  },

  //Tickets
  fetchTicketRevisions: (id) => {
    return axios({
      url: `${Config.url}/audit/ticket/revisions/${id}`,
      method: "GET",
    });
  },
  fetchTicketRevisionByNumber: (revisionNumber) => {
    return axios({
      url: `${Config.url}/audit/ticket/revision/${revisionNumber}`,
      method: "GET",
    });
  },
  fetchTicketRevisionsToCompare: (revisionNumber, revisionNumberToComapare) => {
    return axios({
      url: `${Config.url}/audit/ticket/compareRevisions`,
      method: "GET",
      params: {
        firstRevisionNumber: revisionNumber,
        secondRevisionNumber: revisionNumberToComapare,
      },
    });
  },
};
