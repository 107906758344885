import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import AddIcon from "@mui/icons-material/Add";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";
import { BrowserNotSupported } from "@mui/icons-material";

export const TicketListItems = ({}) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const isUserAuthorized = () => {
    if (auth.token === "") {
      return null;
    }
    const decodedToken = jwtDecode(auth.token);
    const roles = decodedToken.roles;
    const allowedRoles = ["ROLE_SUPERVISION", "ROLE_BUGS", "ROLE_REPORTS"];
    return roles.some((role) => allowedRoles.includes(role));
  };

  const getTicketsText = () => {
    return isUserAuthorized() ? "All Tickets" : "My Tickets";
  };

  return (
    <React.Fragment>
      <ListSubheader component="div">Tickets</ListSubheader>
      <ListItemButton onClick={() => navigate("/tickets/create")}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Open New" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/tickets")}>
        <ListItemIcon>
          <NumbersOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={getTicketsText()} />
      </ListItemButton>
      <div style={{ display: isUserAuthorized() ? "flex" : "none" }}>
        <ListItemButton onClick={() => navigate("/tickets/assigned")}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Assigned" />
        </ListItemButton>
      </div>
      <div style={{ display: isUserAuthorized() ? "flex" : "none" }}>
        <ListItemButton onClick={() => navigate("/tickets/unassigned")}>
          <ListItemIcon>
            <BrowserNotSupported />
          </ListItemIcon>
          <ListItemText primary="Unassigned" />
        </ListItemButton>
      </div>
      <ListItemButton onClick={() => navigate("/tickets/active")}>
        <ListItemIcon>
          <ConfirmationNumberOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Active" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/tickets/closed")}>
        <ListItemIcon>
          <ConfirmationNumberIcon />
        </ListItemIcon>
        <ListItemText primary="Closed" />
      </ListItemButton>
    </React.Fragment>
  );
};
