import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import AddIcon from "@mui/icons-material/Add";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { jwtDecode } from "jwt-decode";

export const UserListItems = ({}) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const isUserAuthorized = () => {
    if (auth.token === "") {
      return null;
    }
    const decodedToken = jwtDecode(auth.token);
    const roles = decodedToken.roles;
    return roles.includes("ROLE_SUPERVISION");
  };

  return (
    <>
      {isUserAuthorized() && (
        <>
          <ListSubheader component="div">Users</ListSubheader>

          <ListItemButton
            onClick={() => {
              navigate("/users");
            }}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="List All" />
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              navigate("/users/add");
            }}
          >
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary="Add Users" />
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              navigate("/groups/add");
            }}
          >
            <ListItemIcon>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText primary="Add Groups" />
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              navigate("/roles/add");
            }}
          >
            <ListItemIcon>
              <WorkspacesIcon />
            </ListItemIcon>
            <ListItemText primary="Add Roles" />
          </ListItemButton>
        </>
      )}
    </>
  );
};
