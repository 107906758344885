import {
  Grid,
  Alert,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Container,
  createTheme,
  ThemeProvider,
  withTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState, useContext, createContext } from "react";
import { useAuth } from "./AuthProvider";
import "../../App.css";
import "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PasswordIcon from "@mui/icons-material/Password";
import EmailIcon from "@mui/icons-material/Email";
import { Navigate, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UsersRepository } from "../../repositories/UsersRepository";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5894b1",
    },
    secondary: {
      main: "#ffab91",
    },
  },
});

export const Login = ({}) => {
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSendUsername, setOpenSendUsername] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [username, setUsername] = useState();
  const [openEmailSentSnackbar, setOpenEmailSentSnackBar] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();

  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });

  useEffect(() => {
    document.title = "Login | Ticketing System";
  }, []);

  useEffect(() => {
    setErrorMessage(auth.errorMessage);
  }, [auth.errorMessage]);

  const handleLogin = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (input.username !== "" && input.password !== "") {
      setLoading(true);
      auth.loginAction(input);
      console.log(auth.errorMessage);
      setInput({
        username: "",
        password: "",
      });
      setLoading(false);
      return;
    } else {
      setErrorMessage("Please provide login credentials!");
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpenSendUsername = () => {
    setOpenSendUsername(true);
  };
  const handleCloseSendUsername = () => {
    setOpenSendUsername(false);
  };

  const handleForgotPassword = (e) => {
    UsersRepository.sendUsername(username)
      .then((res) => {
        console.log(res);
        setOpenEmailSentSnackBar(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "750px",
            width: "500px",
            borderRadius: "50px",
            flexWrap: "wrap",
            boxShadow: "0 10px 80px 5px #5894b1",
            backgroundSize: "1200px",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              marginTop: "-30%",
              width: "40vh",
              height: "80vh",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                fontSize: "50px",
                fontWeight: "bold",
                lineHeight: "1.2",
                fontFamily: "Roboto Slab",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "#5894b1",
                fontFamily: "Dosis",
              }}
            >
              <Grid item xs={12} style={{ marginTop: "5px" }}>
                <dotlottie-player
                  src="https://lottie.host/4ef6d14e-973f-45f5-88b7-3f8a759b319d/0J04cXBnSn.json"
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></dotlottie-player>
              </Grid>
              <Grid item xs={12}>
                <span>
                  Ticket Portal <br />
                  <span style={{ fontSize: "25px", fontWeight: "lighter" }}>
                    Access Your Service Panel
                  </span>
                </span>
              </Grid>
            </Grid>
            {!openSendUsername ? (
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                {errorMessage && (
                  <Grid item xs={12}>
                    <Alert severity="error">{errorMessage}</Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    value={input?.username}
                    label="Username"
                    error={inputErrors?.username ? true : false}
                    helperText={inputErrors?.username}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleLogin(e);
                    }}
                    onChange={(e) => {
                      setInput({ ...input, username: e.target.value });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    type={passwordValues.showPassword ? "text" : "password"}
                    value={input?.password}
                    label="Password"
                    error={inputErrors?.password ? true : false}
                    helperText={inputErrors?.password}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleLogin(e);
                    }}
                    onChange={(e) => {
                      setInput({ ...input, password: e.target.value });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PasswordIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {passwordValues.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button variant="text" onClick={handleOpenSendUsername}>
                    Forgot password?
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "-webkit-box",
                    display: "-webkit-flex",
                    display: "-moz-box",
                    display: "-ms-flexbox",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      width: "200px",
                      display: "block",
                      position: "relative",
                      borderRadius: "25px",
                      overflow: "hidden",
                      margin: "0 auto",
                      boxShadow: "0 5px 15px 0px #5894b1",
                      color: "#3e687c",
                      borderColor: "#5894b1",
                    }}
                    id="loginButton"
                    variant="outlined"
                    disabled={loading}
                    onClick={(e) => {
                      handleLogin(e);
                    }}
                  >
                    Log In
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                {errorMessage && (
                  <Grid item xs={12}>
                    <Alert severity="error">{errorMessage}</Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    value={username || ""}
                    label="Username"
                    error={errorMessage ? true : false}
                    helperText={errorMessage}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleForgotPassword(e);
                    }}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button variant="text" onClick={handleCloseSendUsername}>
                    Back
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "-webkit-box",
                    display: "-webkit-flex",
                    display: "-moz-box",
                    display: "-ms-flexbox",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      width: "200px",
                      display: "block",
                      position: "relative",
                      borderRadius: "25px",
                      overflow: "hidden",
                      margin: "0 auto",
                      boxShadow: "0 5px 15px 0px #5894b1",
                      color: "#3e687c",
                      borderColor: "#5894b1",
                    }}
                    id="loginButton"
                    variant="outlined"
                    disabled={loading}
                    onClick={(e) => {
                      handleForgotPassword(e);
                      setUsername();
                    }}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Snackbar
          open={openSuccessSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSuccessSnackbar()}
        >
          <Alert
            onClose={() => setOpenSuccessSnackbar()}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            User logged in successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openEmailSentSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenEmailSentSnackBar()}
        >
          <Alert
            onClose={() => setOpenEmailSentSnackBar()}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Check you email for the link to reset your password!
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  );
};
